import { deepBoxApi } from '@/api/interceptors'
import type {
  HttpClient,
  HttpClientGetOptions,
  HttpClientResponse,
} from '@/api/http-client'
import type { GenericFolder } from '@/api/types/deepbox/generics.ts'

export default class DeepBoxDeepBoxesBoxesGenericsAPIService {
  private httpClient: HttpClient

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient
  }

  get(
    deepBoxNodeId: string,
    boxId: string,
    params?: Record<string, unknown>,
    options?: HttpClientGetOptions,
  ): Promise<HttpClientResponse<GenericFolder[]>> {
    return this.httpClient.get<GenericFolder[]>(
      `deepBoxes/${deepBoxNodeId}/boxes/${boxId}/generics`,
      params,
      options,
    )
  }
}

export const deepBoxDeepBoxesBoxesGenericsAPI =
  new DeepBoxDeepBoxesBoxesGenericsAPIService(deepBoxApi)
