<template>
  <DeepDialog
    v-model="model"
    :loading="isLoading"
    max-width="1000px"
    :title="title"
  >
    <template #activator="activatorProps">
      <slot name="activator" v-bind="activatorProps"></slot>
    </template>
    <template #content>
      <v-data-table
        density="compact"
        :headers="headers"
        :items="items"
        :items-per-page="-1"
        :loading="isLoading"
        :mobile="false"
      >
        <template #[`item.createdAt`]="{ item }">
          {{ formatDateTimeWithoutSeconds(item.createdAt) }}
        </template>
        <template #[`item.paymentAmount`]="{ item }">
          {{ item.paymentCurrency }} {{ item.paymentAmount }}
        </template>
        <template #[`item.status`]="{ item }">
          <v-chip
            class="mb-1"
            :color="getStatusColor(item.paymentStatus)"
            density="compact"
            size="small"
          >
            {{ getStatusText(item.paymentStatus) }}
          </v-chip>
        </template>
        <template #bottom></template>
      </v-data-table>
    </template>
  </DeepDialog>
</template>

<script setup lang="ts">
import { computed, type PropType, ref, watch } from 'vue'
import {
  DeepDialog,
  formatDateTimeWithoutSeconds,
} from '@deepcloud/deep-ui-lib'
import { useI18n } from 'vue-i18n'
import type {
  PaymentHistory,
  PaymentHistoryDestination,
} from '@/api/types/deepo/payments-history.ts'
import { deepOPaymentsHistoryAPIService } from '@/api/deepo/payments-history.ts'
import { useDeepPayPaymentsHistory } from '@/components/deeppay/paymentshistory/use-deep-pay-payments-history.ts'
import { toast } from 'vue-sonner'

const model = defineModel({ type: Boolean, default: false })

const props = defineProps({
  originOrganizationId: {
    type: String,
    required: true,
  },
  typeId: {
    type: String,
    required: true,
  },
  boxId: {
    type: String,
    required: true,
  },
  destination: {
    type: Object as PropType<PaymentHistoryDestination>,
    required: true,
  },
})

const { getStatusColor, getStatusText, getAddressLines } =
  useDeepPayPaymentsHistory()

const { t } = useI18n()

const items = ref<PaymentHistory[]>([])

const headers = computed(() => [
  {
    title: t('deeppay.payments_history.by_address_dialog.created_at'),
    value: 'createdAt',
    sortable: true,
  },
  {
    title: t('deeppay.payments_history.by_address_dialog.created_by'),
    value: 'createdByName',
    sortable: true,
  },
  {
    title: t('deeppay.payments_history.by_address_dialog.destination_iban'),
    value: 'destination.accountIban',
    sortable: true,
  },
  {
    title: t('deeppay.payments_history.by_address_dialog.status'),
    value: 'status',
    sortable: true,
  },
  {
    title: t('deeppay.payments_history.by_address_dialog.amount'),
    value: 'paymentAmount',
    sortable: true,
    align: 'end',
  },
])

const title = computed(() =>
  t('deeppay.payments_history.by_address_dialog.payment_to', {
    destination: getAddressLines(props.destination).join(', '),
  }),
)

const destinationAddressApiParameters = computed(() => {
  return {
    VendorName: props.destination?.name,
    VendorStreet: props.destination?.street,
    VendorCity: props.destination?.city,
    VendorHouseNr: props.destination?.houseNumber,
    VendorZip: props.destination?.zip,
    VendorCountry: props.destination?.country,
    PaymentIban: props.destination?.accountIban,
  }
})

const isLoading = ref(false)

async function fetchPaymentsHistory() {
  isLoading.value = true
  try {
    const { data } =
      await deepOPaymentsHistoryAPIService.getByDestinationAddress({
        companyId: props.originOrganizationId,
        deepBoxNodeId: props.typeId,
        boxNodeId: props.boxId,
        ...destinationAddressApiParameters.value,
      })
    items.value = data.payments
  } catch {
    toast.error(t('error.error_occurred'))
  } finally {
    isLoading.value = false
  }
}

watch(
  model,
  (value) => {
    if (value) {
      fetchPaymentsHistory()
    }
  },
  { immediate: true },
)
</script>
