<template>
  <div
    class="node-upload-card-wrapper"
    :class="{ 'node-upload-card-wrapper-mobile': mobile }"
  >
    <v-card
      v-if="show"
      class="node-upload-card"
      data-test-id="upload-toolbar"
      density="compact"
      elevation="5"
      max-height="330"
      max-width="350"
      position="absolute"
      rounded="lg"
      :width="expanded ? 350 : undefined"
      @mouseleave="onMouseLeave"
      @mouseover="cancelExpandCloseTimeout"
    >
      <v-toolbar
        class="tasks-card-toolbar cursor-pointer"
        density="compact"
        @click.stop="expanded = !expanded"
      >
        <template #title>
          <span class="mx-1 text-body-1 font-weight-thin">
            {{ t('nodes.upload_card.title') }}
          </span>
          <v-defaults-provider
            :defaults="{
              VIcon: {
                size: '15',
                class: 'ml-2',
              },
            }"
          >
            <v-chip
              v-if="!expanded && deepBoxUploadStore.pollMapCount > 0"
              :append-icon="pollMapStateIcon"
              class="mx-2"
              :color="pollMapStateColor"
              size="small"
            >
              <!-- COMPLETED -->
              <span
                v-if="
                  deepBoxUploadStore.pollMapCount ===
                    deepBoxUploadStore.pollMapCountCompleted ||
                  deepBoxUploadStore.pollMapCountProcessing > 0
                "
              >
                {{ deepBoxUploadStore.pollMapCountCompleted }}
                /
                {{ deepBoxUploadStore.pollMapCount }}
              </span>
              <!-- ERROR or WARNING -->
              <!-- show only warning icon with amount of warning + error -->
              <span
                v-else-if="
                  deepBoxUploadStore.pollMapCountWarning > 0 ||
                  deepBoxUploadStore.pollMapCountError > 0
                "
              >
                {{
                  deepBoxUploadStore.pollMapCountWarning +
                  deepBoxUploadStore.pollMapCountError
                }}
              </span>
              <span v-else>{{ deepBoxUploadStore.pollMapCount }}</span>
            </v-chip>
          </v-defaults-provider>
        </template>
        <template #append>
          <v-btn
            :data-test-id="expanded ? 'collapse' : 'expand'"
            :icon="expanded ? 'fas fa-chevron-down' : 'fas fa-chevron-up'"
            size="small"
            slim
            @click.stop="expanded = !expanded"
          >
          </v-btn>
          <v-btn
            class="mr-1"
            data-test-id="close"
            :disabled="isSomePollMapItemProcessing"
            icon="$close"
            :loading="!expanded && isSomePollMapItemProcessing"
            size="small"
            slim
            @click.stop="show = !show"
          ></v-btn>
        </template>
      </v-toolbar>
      <v-expand-transition mode="out-in">
        <v-card-text v-show="expanded" ref="cardTextRef" class="pa-0">
          <v-list class="py-0" density="compact" slim>
            <v-divider />
            <v-list-item>
              <template #title>
                <span data-test-id="total-files">
                  {{
                    t('nodes.upload_card.labels.count_n_of_total', {
                      n: currentFilter
                        ? deepBoxUploadStore.getPollMapByStatus(currentFilter)
                            .length
                        : deepBoxUploadStore.pollMapCount -
                          deepBoxUploadStore.pollMapNotDoneYetCount,
                      total: deepBoxUploadStore.pollMapCount,
                    })
                  }}
                </span>
                <span
                  class="text-grey text-caption ml-4"
                  data-test-id="total-files-size"
                >
                  {{
                    humanFileSize(deepBoxUploadStore.pollMapTotalSizeUploaded)
                  }}
                  /
                  {{ humanFileSize(deepBoxUploadStore.pollMapTotalSize) }}
                </span>
              </template>
              <template #append>
                <v-btn
                  v-if="deepBoxUploadStore.pollMapCountError > 0"
                  class="text-error pa-2"
                  size="small"
                  @click="
                    currentFilter
                      ? (currentFilter = undefined)
                      : (currentFilter = 'error')
                  "
                >
                  <template #prepend>
                    <v-icon size="x-small">far fa-eye</v-icon>
                  </template>
                  {{
                    t(
                      'nodes.upload_card.labels.count_error',
                      deepBoxUploadStore.pollMapCountError,
                    )
                  }}
                </v-btn>
                <!--  TODO: CANCEL-->
              </template>
            </v-list-item>
            <v-divider />
            <div class="pa-0 overflow-y-auto" style="max-height: 200px">
              <v-list-item
                v-for="currentPollMap in pollMapItemsFiltered.slice().reverse()"
                :key="`current-poll-map-${currentPollMap.id}`"
                :title="getPollMapTitle(currentPollMap)"
              >
                <template v-if="getPollMapTitle(currentPollMap)" #title>
                  <span
                    v-tooltip="{
                      text: getPollMapTitle(currentPollMap),
                      openDelay: 300,
                    }"
                    data-test-id="filename"
                  >
                    {{ getPollMapTitle(currentPollMap) }}
                  </span>
                </template>
                <template #subtitle>
                  <div class="d-flex justify-space-between">
                    <span
                      v-if="currentPollMap.status === 'error'"
                      class="text-error"
                    >
                      <span
                        v-if="
                          currentPollMap.folders &&
                          Object.keys(currentPollMap.folders).length === 1 &&
                          Object.values(currentPollMap.folders)[0].data
                        "
                      >
                        {{
                          getPollMapErrorMsgFormatted(
                            Object.values(currentPollMap.folders)[0].data
                              ?.error,
                          )
                        }}
                      </span>
                      <span
                        v-else-if="
                          currentPollMap.files &&
                          currentPollMap.files.length === 1 &&
                          currentPollMap.files[0].data
                        "
                      >
                        {{
                          getPollMapErrorMsgFormatted(
                            currentPollMap.files[0].data?.error,
                          )
                        }}
                      </span>
                    </span>
                    <span data-test-id="filesize">
                      {{ getPollMapTotalSizeDisplay(currentPollMap) }}
                    </span>
                  </div>
                </template>
                <template #prepend>
                  <v-avatar
                    v-if="getPollMapIconNode(currentPollMap)"
                    size="20"
                    tile
                    @click="
                      onOpenNodeUploadDialog(currentPollMap.id, currentPollMap)
                    "
                  >
                    <v-img
                      alt="node-icon"
                      class="mx-1"
                      :src="`/svg/files/${getNodeIcon(
                        getPollMapIconNode(currentPollMap),
                      )}-20-px.svg`"
                    />
                  </v-avatar>
                  <v-icon
                    v-else
                    v-bind="getPollMapIcon(currentPollMap)"
                    @click="
                      onOpenNodeUploadDialog(currentPollMap.id, currentPollMap)
                    "
                  />
                </template>
                <template #append>
                  <!-- Multiple upload counter-->
                  <v-chip
                    v-if="getPollMapItemsCountTotal(currentPollMap) > 1"
                    class="pt-1"
                    size="small"
                    variant="text"
                  >
                    {{
                      getPollMapItemsCountWithCompletedStatus(currentPollMap)
                    }}
                    /
                    {{ getPollMapItemsCountTotal(currentPollMap) }}
                  </v-chip>

                  <v-icon
                    v-if="
                      currentPollMap.status === 'processing' ||
                      currentPollMap.status === 'added'
                    "
                    v-tooltip="t(`upload.status.${currentPollMap.status}`)"
                    class="mx-2"
                    @click="
                      onOpenNodeUploadDialog(currentPollMap.id, currentPollMap)
                    "
                  >
                    <v-progress-circular
                      :indeterminate="
                        currentPollMap.status === 'processing' &&
                        !getPollMapItemProgress(currentPollMap)
                      "
                      :model-value="
                        getPollMapItemProgress(currentPollMap) || undefined
                      "
                      :rotate="
                        getPollMapItemProgress(currentPollMap) ? 360 : undefined
                      "
                      width="2"
                    >
                    </v-progress-circular>
                  </v-icon>
                  <v-icon
                    v-else-if="
                      [POLL_MAP_STATUS.ERROR, POLL_MAP_STATUS.WARNING].includes(
                        currentPollMap.status,
                      )
                    "
                    v-bind="getPollMapStateIcon(currentPollMap.status)"
                    class="mx-2"
                    @click="
                      onOpenNodeUploadDialog(currentPollMap.id, currentPollMap)
                    "
                  ></v-icon>
                  <v-hover v-else v-slot="{ isHovering, props: HoverProps }">
                    <div v-bind="HoverProps">
                      <v-btn
                        v-if="isHovering && currentPollMap.route"
                        v-tooltip="{
                          text: t('tooltip.open'),
                          openDelay: 300,
                        }"
                        v-bind="HoverProps"
                        data-test-id="go-to-file"
                        :icon="
                          isHovering
                            ? 'far fa-folder'
                            : getPollMapStateIcon(currentPollMap.status)
                        "
                        size="x-small"
                        slim
                        :to="currentPollMap.route"
                        variant="plain"
                      >
                      </v-btn>
                      <v-icon
                        v-else
                        v-tooltip="{
                          text: t(`upload.status.${currentPollMap.status}`),
                          openDelay: 300,
                        }"
                        v-bind="{
                          ...HoverProps,
                          ...getPollMapStateIcon(currentPollMap.status),
                        }"
                        class="mx-2"
                        :data-test-id="`upload-${currentPollMap.status}`"
                      ></v-icon>
                    </div>
                  </v-hover>
                </template>
              </v-list-item>
            </div>
          </v-list>
        </v-card-text>
      </v-expand-transition>
      <NodeUploadDialog
        v-model="showNodeUploadDialog"
        :data="currentDataNodeUploadDialog"
      />
    </v-card>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'

// components
import NodeUploadDialog from '@/components/node/NodeUploadDialog.vue'

// composables
import { useDisplay, useGoTo } from 'vuetify'
import { useRoute } from 'vue-router'
import { useNodeUpload } from '@/components/node/use-node-upload.ts'

// stores
import { useDeepBoxUploadStore } from '@/stores/deepbox/deepboxes/upload.ts'

// utilities
import { getNodeIcon } from '@/utils/helpers/nodes.ts'
import { humanFileSize } from '@deepcloud/deep-ui-lib'

// types & constants
import {
  POLL_MAP_STATUS,
  type PollMap,
  type PollMapStatus,
} from '@/stores/deepbox/deepboxes/upload.ts'
import { useI18n } from 'vue-i18n'

const deepBoxUploadStore = useDeepBoxUploadStore()

const goTo = useGoTo()
const { t } = useI18n()
const {
  pollMapStateColor,
  pollMapStateIcon,
  getPollMapIconNode,
  getPollMapStateIcon,
  getPollMapTotalSizeDisplay,
  getPollMapErrorMsgFormatted,
} = useNodeUpload()
const { mobile } = useDisplay()

const showNodeUploadDialog = ref(false)
const currentDataNodeUploadDialog = ref()

const expanded = ref(false)
const show = ref(false)
watch(
  () => show.value,
  (newValue) => {
    if (!newValue) {
      deepBoxUploadStore.pollMap = []
    }
  },
)

const cardTextRef = ref()

watch(
  () => deepBoxUploadStore.pollMapCount,
  (newValue) => {
    if (newValue > 0) {
      show.value = true
      expanded.value = true
      if (cardTextRef.value) {
        // always scroll to top
        goTo(0, {
          container: cardTextRef.value,
        })
      }
    } else {
      show.value = false
      expanded.value = false
    }
  },
  { immediate: true, deep: true },
)

const currentFilter = ref<PollMapStatus>()

const pollMapItemsFiltered = computed(() =>
  currentFilter.value
    ? deepBoxUploadStore.getPollMapByStatus(currentFilter.value)
    : deepBoxUploadStore.pollMap,
)

const isSomePollMapItemProcessing = computed(() =>
  deepBoxUploadStore.pollMap.some((t) => t.status === 'processing'),
)

function getPollMapItemsFoldersCount(pollMap: PollMap) {
  return pollMap.folders ? Object.keys(pollMap.folders).length : 0
}

function getPollMapItemsFilesCount(pollMap: PollMap) {
  return pollMap.files ? pollMap.files?.length : 0
}

function getPollMapItemsCountTotal(pollMap: PollMap) {
  return (
    getPollMapItemsFoldersCount(pollMap) + getPollMapItemsFilesCount(pollMap)
  )
}

function getPollMapItemsCountWithCompletedStatus(pollMap: PollMap) {
  const foldersCountCompleted = pollMap.folders
    ? Object.values(pollMap.folders).filter((f) => f.status === 'completed')
        .length
    : 0
  const filesCountCompleted = pollMap.files
    ? pollMap.files.filter((f) => f.status === 'completed').length
    : 0

  return foldersCountCompleted + filesCountCompleted
}

function getPollMapItemProgress(pollMap: PollMap) {
  return (
    (100 * getPollMapItemsCountWithCompletedStatus(pollMap)) /
    getPollMapItemsCountTotal(pollMap)
  )
}

function getPollMapTitle(pollMap: PollMap) {
  if (!pollMap.folders && !pollMap.files) return undefined
  const foldersCount = getPollMapItemsFoldersCount(pollMap)

  if (foldersCount > 0) {
    // show only folder name
    if (pollMap.folders) {
      return Object.values(pollMap.folders)[0].data?.name
    }
  } else {
    // only files
    // show only file name
    if (pollMap.files) {
      return (
        pollMap.files[0].data?.node?.displayName ||
        pollMap.files[0].data?.node?.name ||
        pollMap.files[0].data?.file?.name ||
        pollMap.files[0].data?.name
      )
    }
  }
  return ''
}

function getPollMapIcon(pollMap: PollMap) {
  const foldersCount = getPollMapItemsFoldersCount(pollMap)
  if (foldersCount > 0) {
    return {
      icon: 'far fa-folder-arrow-up',
    }
  }
  const filesCount = getPollMapItemsFilesCount(pollMap)
  if (filesCount === 1) {
    console.warn('TODO: show icon for only 1 file')
  }
  return {
    icon: 'far fa-upload',
  }
}

const route = useRoute()
watch(
  () => route.name,
  (newValue) => {
    if (newValue && !isSomePollMapItemProcessing.value) {
      expanded.value = false
    }
  },
)

const isExpandCloseWaiting = ref(false)
watch(
  () => isSomePollMapItemProcessing.value,
  (newValue) => {
    if (!newValue && show.value) {
      isExpandCloseWaiting.value = true
      startExpandCloseTimeout()
    } else {
      cancelExpandCloseTimeout()
    }
  },
)

function onMouseLeave() {
  if (isExpandCloseWaiting.value) {
    startExpandCloseTimeout()
  }
}

const expandCloseTimeout = ref<ReturnType<typeof setTimeout>>()

function startExpandCloseTimeout() {
  expandCloseTimeout.value = setTimeout(() => {
    expanded.value = false
    isExpandCloseWaiting.value = false
  }, 5000)
}

function cancelExpandCloseTimeout() {
  clearTimeout(expandCloseTimeout.value)
}

function maybePreventReload(event: BeforeUnloadEvent) {
  if (!isSomePollMapItemProcessing.value) return
  event.preventDefault()
}

// prevent window reload until is loading
onMounted(() => {
  window.addEventListener('beforeunload', maybePreventReload)
})

onUnmounted(() => {
  window.removeEventListener('beforeunload', maybePreventReload)
  // clear the upload store when the component is Unmounted;
  if (!isSomePollMapItemProcessing.value) {
    deepBoxUploadStore.$reset()
  }
})

function onOpenNodeUploadDialog(pollMapId: string, pollMap: PollMap) {
  currentDataNodeUploadDialog.value = { [pollMapId]: pollMap }
  showNodeUploadDialog.value = true
}
</script>
<style lang="scss" scoped>
.node-upload-card-wrapper {
  bottom: 24px;
  display: block;
  left: auto;
  max-height: 323px;
  position: relative;
  right: 24px;
  z-index: 1005;

  &.node-upload-card-wrapper {
    right: 0 !important;
  }
}

.node-upload-card {
  right: 0 !important;
  bottom: 0 !important;
}
</style>
