// Sentry
import {
  browserTracingIntegration,
  init,
  replayIntegration,
  vueIntegration,
} from '@sentry/vue'
import initializeRouter from '@/router'
import type { App } from 'vue'

export const sentry = (app: App) => {
  console.log('installing sentry plugin')

  if (import.meta.env.VITE_SENTRY_ENABLED === 'true') {
    console.log('Sentry is enabled')
    init({
      app,
      dsn: import.meta.env.VITE_SENTRY_DSN,
      integrations: [
        browserTracingIntegration({
          router: initializeRouter(),
          shouldCreateSpanForRequest: (url) => !url.match(/\/count_unread$/),
        }),
        replayIntegration(),
        vueIntegration({
          tracingOptions: {
            trackComponents: true,
          },
        }),
      ],
      tracePropagationTargets: [
        // deep-a api
        // /https:\/\/deep-llm-proxy.(?:[a-z]+\.)?deepcloud\.swiss\/api/,
        // deep-admin api
        /https:\/\/(?:[a-z]+\.)?deepcloud\.swiss\/api/,
        // deep-box api
        /https:\/\/api(?:\.[a-z]+)?\.deepbox\.swiss\/api/,
        // deep-o api
        /https:\/\/deepo(?:\.[a-z]+)?\.deepbox\.swiss\/api/,
        // deep-sign api
        /https:\/\/api(?:\.[a-z]+)?\.sign.deepbox\.swiss\/api/,
        // deep-onboarding api
        /https:\/\/(?:[a-z]+\.)?deepcloud\.swiss\/api-internal/,
      ],
      environment: import.meta.env.VITE_ENVIRONMENT || 'dev',
      release: import.meta.env.VITE_SENTRY_RELEASE,
      debug: import.meta.env.VITE_SENTRY_DEBUG === 'true',
      tracesSampleRate: parseFloat(
        import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE,
      ),
      replaysSessionSampleRate: parseFloat(
        import.meta.env.VITE_SENTRY_REPLAYS_SESSION_SAMPLE_RATE,
      ),
      replaysOnErrorSampleRate: 1.0,
      ignoreErrors: [
        'Request failed with status code',
        'Request aborted',
        'Network Error',
        'FILE_NOT_FOUND',
        'Error on update token', // from use-keycloak
      ],
    })
  }
}

export default sentry
