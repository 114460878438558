<template>
  <v-list-item density="compact" slim variant="text" v-bind="$attrs">
    <template #prepend>
      <div class="mr-2">
        <!-- INITIALS -->
        <BoxAvatar
          v-if="isShared"
          :initials-size="boxAvatarInitialsSize"
          :name="'company' in box ? box.company.displayName : undefined"
        />
        <!-- /INITIALS -->

        <!-- ICON -->
        <BoxAvatar v-else :box-size="boxAvatarSize" />
        <!-- /ICON -->
      </div>
    </template>
    <template #title>
      <BoxItemTitle
        :box="box"
        :box-avatar-initials-size="boxAvatarInitialsSize"
        :box-avatar-size="boxAvatarSize"
        :class="{
          'flex-grow-1': !isDeadline,
          'flex-column': !isDeadline,
        }"
        :is-box-selection-menu="isBoxSelectionMenu"
        :is-deadline="isDeadline"
        :is-favourite="isFavourite"
        :is-inbox="isInbox"
        :is-recent="isRecent"
        :is-shared="isShared"
        :show-box-name-subtitle="showBoxNameSubtitle"
      />
    </template>
    <!--
      Dynamically inherit slots from parent
      & make all slots from component reusable from parent
    -->
    <template v-for="(_, slotName) in $slots" #[slotName]="slotData">
      <slot :name="slotName" v-bind="slotData" />
    </template>
  </v-list-item>
</template>

<script lang="ts" setup>
import { type PropType } from 'vue'

// components
import BoxAvatar from '@/components/box/BoxAvatar.vue'
import BoxItemTitle from '@/components/box/BoxItemTitle.vue'

// types & constants
import type { Box, BoxEntry, BoxInfo } from '@/api/types/deepbox/box'

defineProps({
  isShared: {
    type: Boolean,
    default: false,
  },
  isInbox: {
    type: Boolean,
    default: false,
  },
  isRecent: {
    type: Boolean,
    default: false,
  },
  isFavourite: {
    type: Boolean,
    default: false,
  },
  isDeadline: {
    type: Boolean,
    default: false,
  },
  box: {
    type: Object as PropType<BoxInfo | Box | BoxEntry>,
    default: undefined,
  },
  boxAvatarSize: {
    type: [String, Number],
    default: undefined,
  },
  boxAvatarInitialsSize: {
    type: String,
    default: undefined,
  },
  isBoxSelectionMenu: {
    type: Boolean,
    default: false,
  },
  showBoxNameSubtitle: {
    type: Boolean,
    default: false,
  },
})
</script>
