import type { AdminBox } from '@/api/types/deepbox/admin'

export function useBoxAdminPolicy() {
  function canAdminAccess(adminBox: AdminBox) {
    return adminBox?.boxPolicy?.canAdminAccess || false
  }

  function canAdminAddresses(adminBox: AdminBox) {
    return adminBox?.boxPolicy?.canAdminAddresses || false
  }

  function canAdminGenerics(adminBox: AdminBox) {
    return adminBox?.boxPolicy?.canAdminGenerics || false
  }

  function canAdminRelations(adminBox: AdminBox) {
    return adminBox?.boxPolicy?.canAdminRelations || false
  }

  function canAdminShares(adminBox: AdminBox) {
    return adminBox?.boxPolicy?.canAdminShares || false
  }

  function canAdminVariant(adminBox: AdminBox) {
    return adminBox?.boxPolicy?.canAdminVariant || false
  }

  function canAdminWebhooks(adminBox: AdminBox) {
    return adminBox?.boxPolicy?.canAdminWebhooks || false
  }

  function canPurge(adminBox: AdminBox) {
    return adminBox?.boxPolicy?.canPurge || false
  }

  function canRename(adminBox: AdminBox) {
    return adminBox?.boxPolicy?.canRename || false
  }

  return {
    canAdminAccess,
    canAdminAddresses,
    canAdminGenerics,
    canAdminRelations,
    canAdminShares,
    canAdminVariant,
    canAdminWebhooks,
    canPurge,
    canRename,
  }
}
