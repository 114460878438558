import type {
  IDeepBoxError,
  IDeepBoxErrorBtn,
  IDeepBoxErrorIcon,
  IDeepBoxErrorImg,
} from '@/api/types/error'
import { Component } from 'vue'

export class DeepBoxError extends Error {
  title?: string
  description?: string
  img?: IDeepBoxErrorImg
  imgComponent?: Component
  icon?: IDeepBoxErrorIcon
  statusCode?: string | number
  hideStatusCode?: boolean
  deepBoxError?: boolean
  btn?: IDeepBoxErrorBtn

  constructor(message?: string, options?: Partial<IDeepBoxError>) {
    super(message)
    this.name = 'DeepBoxError'
    this.title = options?.title
    this.description = options?.description
    this.img = options?.img
    this.imgComponent = options?.imgComponent
    this.icon = options?.icon
    this.statusCode = options?.statusCode
    this.hideStatusCode = options?.hideStatusCode
    this.deepBoxError = true
    this.btn = options?.btn
  }
}
