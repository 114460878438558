import { computed, ref } from 'vue'
import { acceptHMRUpdate, defineStore } from 'pinia'
import type { BoxInfo } from '@/api/types/deepbox/box'
import { deepBoxBoxesInfoAPI } from '@/api/deepbox/boxes/boxes-info'
import { deepBoxDeepBoxesBoxesQueueAPI } from '@/api/deepbox/deepboxes/deepboxes-boxes-queue.ts'
import DeepErrorHandlerService from '@/services/DeepErrorHandlerService.ts'
import { DeepBoxError } from '@/models'
import { useDeepAdminUsersMeStore } from '@/stores/deepadmin/users/users-me.ts'

export const useDeepBoxBoxInfoStore = defineStore('deepBoxBoxInfo', () => {
  const boxInfo = ref<BoxInfo>()
  const fetchBoxInfoPending = ref(false)

  async function fetchBoxInfo(boxNodeId: string) {
    fetchBoxInfoPending.value = true
    try {
      const res = await deepBoxBoxesInfoAPI.get(boxNodeId)
      const resData = res.data
      boxInfo.value = { ...resData }

      return Promise.resolve(res)
    } catch (e: unknown) {
      if (e && e.response) {
        const errorCode = e.response.status
        if ([403, 404].includes(errorCode)) {
          DeepErrorHandlerService.error(
            new DeepBoxError('error.403.box_not_found_or_no_access', {
              statusCode: errorCode,
            }),
          )
        } else if (errorCode === 400) {
          DeepErrorHandlerService.error(
            new DeepBoxError('error.invalid_or_missing_params', {
              statusCode: errorCode,
            }),
          )
        } else {
          DeepErrorHandlerService.error(
            new DeepBoxError('error.error_occurred', {
              statusCode: errorCode,
            }),
          )
        }
      }

      return Promise.reject(e)
    } finally {
      fetchBoxInfoPending.value = false
    }
  }

  const deepAdminUsersMeStore = useDeepAdminUsersMeStore()
  const accessCompany = computed(() => {
    if (!boxInfo.value || boxInfo.value?.accessGrantedBy.length === 0) return

    const preferredOrganization = boxInfo.value?.accessGrantedBy.find(
      (o) => o.companyId === deepAdminUsersMeStore.selectedOrganizationId,
    )

    return preferredOrganization || boxInfo.value?.accessGrantedBy[0]
  })

  const isOwnedBox = computed(() => {
    if (!boxInfo.value?.company) return false

    const accessGrantedByCompanyIds = boxInfo.value.accessGrantedBy.map(
      (c) => c.companyId,
    )

    return accessGrantedByCompanyIds.includes(boxInfo.value?.company?.companyId)
  })

  async function updateBoxInfoQueueCounterByAPI(typeId: string, boxId: string) {
    if (!boxInfo.value) return
    // this fn should update the queueCount prop inside "boxInfo" in the store: deepBoxBoxInfoStore
    // we use the API: deepBoxDeepBoxesBoxesQueueAPI to make the call faster as "re-fetch" the boxInfo

    const res = await deepBoxDeepBoxesBoxesQueueAPI.get(typeId, boxId, {
      // the limit = 0 is to make the API faster as we only need the "size" from the response
      limit: 0,
    })

    boxInfo.value = {
      ...boxInfo.value,
      queueCount: res.data.size,
    }
  }

  function increaseBoxInfoQueueCounter(count = 1) {
    if (!boxInfo.value) return
    boxInfo.value = {
      ...boxInfo.value,
      queueCount: boxInfo.value.queueCount + count,
    }
  }

  function decreaseBoxInfoQueueCounter(count = 1) {
    if (!boxInfo.value) return
    boxInfo.value = {
      ...boxInfo.value,
      queueCount: boxInfo.value.queueCount - count,
    }
  }

  return {
    // state
    boxInfo,
    fetchBoxInfoPending,
    accessCompany,
    isOwnedBox,
    // actions
    fetchBoxInfo,

    updateBoxInfoQueueCounterByAPI,
    increaseBoxInfoQueueCounter,
    decreaseBoxInfoQueueCounter,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useDeepBoxBoxInfoStore, import.meta.hot),
  )
}
