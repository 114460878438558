import { deepAdminApi } from '@/api/interceptors'
import type { HttpClient, HttpClientResponse } from '@/api/http-client'
import type {
  EmailCheckPayload,
  EmailCheckResponse,
} from '@/api/types/deepadmin/users/users-check-email.ts'

export default class DeepAdminUsersCheckEmailAPIService {
  private httpClient: HttpClient

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient
  }

  post(
    payload: EmailCheckPayload,
  ): Promise<HttpClientResponse<EmailCheckResponse>> {
    return this.httpClient.post<EmailCheckResponse>(
      'users/check_email/',
      payload,
    )
  }
}

export const deepAdminUsersCheckEmailAPI =
  new DeepAdminUsersCheckEmailAPIService(deepAdminApi)
