import { computed, type Ref } from 'vue'
import type { AdminBox } from '@/api/types/deepbox/admin.ts'
import { useBoxAdminPolicy } from '@/composables/use-box-admin-policy.ts'
import { useDisplay } from 'vuetify'
import { useOrganization } from '@/composables/use-organization.ts'

export function useBoxAdmin(adminBox: Ref<AdminBox | undefined>) {
  const { canAdminAccess } = useBoxAdminPolicy()

  const { xs } = useDisplay()

  const userCanAdminAccess = computed(
    () =>
      (adminBox.value && canAdminAccess(adminBox.value) && !xs.value) || false,
  )

  const { currentContextOrgId } = useOrganization()

  const adminOrAccessOrgId = computed(() =>
    adminBox.value && userCanAdminAccess.value
      ? adminBox.value.companyId
      : currentContextOrgId.value,
  )

  return {
    userCanAdminAccess,
    adminOrAccessOrgId,
  }
}
