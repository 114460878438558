<template>
  <v-menu
    v-if="
      organizationHasServicesPermission &&
      sortedServices.length > 0 &&
      currentOrganization &&
      smAndUp
    "
    v-model="servicesMenu"
    :close-on-content-click="false"
    location="bottom right"
  >
    <template #activator="{ props: ActivatorProps }">
      <v-btn v-bind="ActivatorProps" class="pa-0" variant="text">
        <v-icon>fas fa-puzzle</v-icon>
        <v-icon class="mx-1" :end="device?.isTablet">
          {{ servicesMenu ? 'far fa-angle-up' : 'far fa-angle-down' }}
        </v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item :title="t('external_services.title')" />
      <v-divider />
      <v-list-item
        v-for="(service, index) in sortedServices"
        :key="index"
        :subtitle="service.subtitle"
        :title="service.title"
        :to="service.href"
      >
        <template #prepend>
          <v-avatar rounded="lg" tile>
            <DeepAppIcon :logo="service.logoId" :width="36" />
          </v-avatar>
        </template>
        <template #append>
          <v-icon>far fa-angle-right</v-icon>
        </template>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script lang="ts" setup>
import { constants } from '@/constants/constants'
import { computed, inject, type PropType, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useOrgBetaFeatures } from '@/composables/use-org-beta-features'
import type { Box, BoxInfo } from '@/api/types/deepbox/box.ts'
import { useBoxInfo } from '@/composables/use-box-info.ts'
import { useBox } from '@/composables/use-box.ts'
import { DeviceKey } from '@/plugins/device-detector-js.ts'
import helpers from '@/utils/helpers.ts'
import type { UserMeOrganization } from '@/api/types/deepadmin/users/user.ts'
import { DeepAppIcon } from '@deepcloud/deep-ui-lib'
import { useDisplay } from 'vuetify'

const props = defineProps({
  currentOrganization: {
    type: Object as PropType<UserMeOrganization>,
    default: undefined,
  },
  box: {
    type: Object as PropType<Box | BoxInfo>,
    default: undefined,
  },
})

const { t } = useI18n()
const device = inject(DeviceKey)
const { smAndUp } = useDisplay()

const { isAbaNinjaBoxEnabled } = useOrgBetaFeatures()

const servicesMenu = ref(false)

const parentBoxId = computed(() => props.box?.boxNodeId)

const { hasBoxRelationByBoxType } = useBoxInfo(
  computed(() => props.box as BoxInfo),
)

const { isBoxVariantAdvanced, isBoxVariantAdvancedPerUse } = useBox(
  computed(() => props.box as BoxInfo),
)

const services = computed(() => [
  {
    key: constants.STORE_SERVICE_HOOP,
    title: 'Hoop',
    subtitle: t('external_services.hoop.service_name'),
    logoId: 'hoop',
    href: {
      name: 'organization-store-services-service',
      params: {
        organization: props.currentOrganization?.group_id,
        service: constants.STORE_SERVICE_HOOP,
      },
      query: {
        parentBoxId: parentBoxId.value,
      },
    },
    show: () => !helpers.isPersonalStructure(props.currentOrganization),
  },
  {
    key: constants.STORE_SERVICE_ABA_NINJA,
    title: 'DeepNinja',
    subtitle: t('external_services.aba_ninja.service_name'),
    logoId: 'deepninja',
    href: {
      name: 'organization-store-services-service',
      params: {
        organization: props.currentOrganization?.group_id,
        service: constants.STORE_SERVICE_ABA_NINJA,
      },
      query: {
        parentBoxId: parentBoxId.value,
      },
    },
    show: () => {
      const isOrgValid =
        props.currentOrganization &&
        props.currentOrganization.group_id &&
        isAbaNinjaBoxEnabled(props.currentOrganization.group_id)
      if (props.box) {
        return (
          isOrgValid &&
          props.box &&
          (isBoxVariantAdvanced.value || isBoxVariantAdvancedPerUse.value) &&
          !hasBoxRelationByBoxType('deep-ninja')
        )
      }
      return isOrgValid
    },
  },
])

const sortedServices = computed(() =>
  services.value.filter((service) => service.show()),
)

const organizationHasServicesPermission = computed(
  () => sortedServices.value.length > 0,
)
</script>

<style scoped lang="scss">
.service-name {
  display: block;
  max-width: 165px !important;
}
</style>
