import { ref } from 'vue'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { DeepBoxError } from '@/models'

export const useErrorStore = defineStore('error', () => {
  const error = ref<Error | DeepBoxError>()

  function create(e: Error | DeepBoxError) {
    error.value = e
  }

  return {
    error,
    // actions
    create,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useErrorStore, import.meta.hot))
}
