import { humanFileSize } from '@deepcloud/deep-ui-lib'
import { constants } from '@/constants'
import { toast } from 'vue-sonner'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { deepSignDocumentsAPI } from '@/api/deepsign/documents'
import type { Node } from '@/api/types/deepbox/node.ts'
import type { OverviewDocument } from '@/api/types/deepsign/overview.ts'
import { useOrganization } from '@/composables/use-organization.ts'

export function useDeepSign() {
  const { t } = useI18n()
  const router = useRouter()

  const { currentContextOrgId } = useOrganization()

  /**
   * Check is user can open the document on DeepSign
   * @param item
   * @return {Promise<boolean>}
   */
  async function canOpenDocumentOnDeepsign(item: Node) {
    if (!item) return false
    // TODO : this is a workaround waiting for https://jira.abacus.ch/browse/DEEPBOX-1185
    // see https://jira.abacus.ch/browse/DEEPBOX-2074

    if (item.signStatus === null) {
      return true
    }

    try {
      //TODO: WARNING! API is deprecated
      const res = await deepSignDocumentsAPI.get({
        filterDeepboxDocumentId: item.nodeId,
        filterDocumentStatus: item.status,
      })
      const resData = res?.data
      if (
        item.policy.canSign &&
        resData.size === 0 &&
        [
          constants.DEEP_SIGN_SIGN_STATUS_IN_PROGRESS,
          constants.DEEP_SIGN_SIGN_STATUS_SIGNED,
        ].includes(item.signStatus)
      ) {
        return false
      }

      if (item.policy.canSign && resData.size === 0) {
        return true
      }

      return resData?.size > 0
    } catch (e) {
      console.log(e)
      return false
    }
  }

  function getDeepSignDeepBoxUrl(node: Node, orgId: string) {
    const signStatus = node.signStatus
    let url = import.meta.env.VITE_DEEPSIGN_URL

    switch (signStatus) {
      case 'in-progress':
        url += `deepbox/${node.nodeId}/pending`
        break
      case 'signed':
        url += `deepbox/${node.nodeId}/signed`
        break
      default:
        url += `deepbox/${node.nodeId}`
        break
    }

    const deepSignUrl = new URL(url)

    deepSignUrl.searchParams.append('backUrl', window.location.href)

    deepSignUrl.searchParams.append('companyId', orgId)

    return deepSignUrl.href
  }

  function isNodeTooBigForSign(node: Node) {
    return node.size > constants.SIGN_MAX_SIZE
  }

  function openDeepSignDocument(node: Node) {
    if (isNodeTooBigForSign(node)) {
      toast.error(
        t('messages.error.deepsign_file_max_size', {
          size: humanFileSize(constants.SIGN_MAX_SIZE, 0),
        }),
      )
      return
    }
    const url = getDeepSignDeepBoxUrl(node, currentContextOrgId.value ?? '')

    window.open(url, '_self')
  }

  function getSignatureStatusIcon(node: Node) {
    const status = node?.signStatus || ''
    switch (status) {
      case constants.DEEP_SIGN_SIGN_STATUS_IN_PROGRESS:
        return 'fas fa-hourglass-start'
      case constants.DEEP_SIGN_SIGN_STATUS_SIGNED:
        return 'far fa-circle-check'
      default:
        return 'fas fa-pen-nib'
    }
  }

  async function openDeepSignOrPreviewDocument(node: Node) {
    if (node?.signStatus) {
      const canOpen = await canOpenDocumentOnDeepsign(node)
      if (canOpen) {
        openDeepSignDocument(node)
      } else {
        // Fallback if user can not open DeepSign
        void router.push({
          name: 'node-preview',
          params: {
            node: node.nodeId,
          },
        })
      }
    } else {
      openDeepSignDocument(node)
    }
  }

  function getDeepSignUrl(document: OverviewDocument) {
    const deepSignUrl = new URL(
      `${import.meta.env.VITE_DEEPSIGN_URL}document/${document.documentId}/sign`,
    )

    deepSignUrl.searchParams.append('backUrl', window.location.href)

    return deepSignUrl.href
  }

  return {
    openDeepSignDocument,
    getSignatureStatusIcon,
    canOpenDocumentOnDeepsign,
    openDeepSignOrPreviewDocument,
    getDeepSignUrl,
  }
}
