<template>
  <div v-if="comment" class="pa-0 ma-0">
    <v-sheet
      class="my-2"
      :color="highlight ? 'primary' : 'transparent'"
      flat
      rounded
    >
      <v-card
        class="pa-2 pa-0 ma-0"
        :color="highlight ? 'grey-lighten-3' : ''"
        flat
        :loading="comment.isLoading"
        style="background-color: transparent"
      >
        <v-list-item
          class="pa-0 ma-0"
          density="compact"
          slim
          :title="user ? user.displayName : comment.created.user.displayName"
        >
          <template #prepend>
            <DeepAvatar v-if="user" color="transparent" size="32" tile>
              <template #logo>
                <VImg alt="user-logo-comment" :src="user.logo"></VImg>
              </template>
            </DeepAvatar>
            <DeepAvatar
              v-else
              class="mr-1"
              :color="`hsla(${comment.created.user.hue},100%,50%,0.3)`"
              size="32"
              :style="{
                color: `hsla(${comment.created.user.hue},10%,50%,1.0)`,
              }"
            >
              <template #initials>
                {{ getInitials(comment.created.user.displayName) }}
              </template>
            </DeepAvatar>
          </template>
          <v-list-item-subtitle class="text-caption">
            <span
              v-tooltip="{
                text: formatDateTimeByDefaultFormat(comment.created.time),
                location: 'top',
                offset: 3,
                openDelay: 300,
              }"
            >
              {{ getDateTimeSince(comment.created.time, i18n) }}
            </span>
            <span
              v-if="isModified"
              v-tooltip="{
                text: t('core.comments.edited.tooltip', {
                  user: modifiedUserDisplayName,
                  date_time: formatDateTimeByDefaultFormat(modifiedTime),
                }),
                location: 'top',
                offset: 3,
                openDelay: 300,
              }"
              class="pa-0 ma-0"
            >
              · {{ t('core.comments.edited.label') }}
            </span>
          </v-list-item-subtitle>
          <template v-if="menuItems.length > 0" #append>
            <DeepMenu :items="menuItems">
              <template #activator="{ props: ActivatorProps }">
                <v-btn
                  v-bind="ActivatorProps"
                  icon="fas fa-ellipsis-vertical"
                  name="btn-comment-item-menu"
                  size="x-small"
                  slim
                  variant="text"
                >
                </v-btn>
              </template>
            </DeepMenu>
          </template>
        </v-list-item>
        <CoreCommentTextarea
          v-if="comment.isEditMode"
          ref="commentTextarea"
          v-model.trim="commentEdit"
          autofocus
          :counter="maxLength"
          :disabled="comment.isLoading"
          edit-mode
          :rows="3"
          :rules="rules"
          @comment:add="onCommentEdit"
          @is-field-focused="isCommentFieldFocused = $event"
        >
          <template v-if="isCommentFieldFocused" #actions>
            <v-spacer />
            <VBtnTertiary
              :disabled="comment.isLoading"
              name="btn-comment-item-edit-cancel"
              @click="onEditModeCancel"
            >
              {{ t('core.comments.btn.cancel') }}
            </VBtnTertiary>
            <VBtnPrimary
              :disabled="!isValid"
              :loading="comment.isLoading"
              name="btn-comment-item-edit-save"
              @click="onCommentEdit"
            >
              <template #loader>
                <v-progress-circular
                  color="white"
                  indeterminate
                  :size="10"
                  :width="1"
                />
              </template>
              {{ t('core.comments.btn.save') }}
            </VBtnPrimary>
          </template>
        </CoreCommentTextarea>

        <v-row v-else class="pl-9 pr-2 pb-0 pt-2">
          <v-col>
            <div
              style="
                white-space: pre-line;
                font-size: 13px;
                font-weight: 400;
                line-height: 16px;
              "
            >
              {{ comment.text }}
            </div>
          </v-col>
          <v-col v-if="comment.hasMore" align="end" cols="12">
            <v-btn color="primary" variant="plain" @click="loadCompleteComment">
              {{ t('core.comments.load_more') }}
              <span v-if="loadingComment" class="loading-ellipsis"></span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-sheet>
    <v-divider class="mt-2" />
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import {
  DeepAvatar,
  DeepMenu,
  formatDateTimeByDefaultFormat,
  getDateTimeSince,
  getInitials,
  type MenuUserItem,
} from '@deepcloud/deep-ui-lib'
import CoreCommentTextarea from '@/components/core/comments/CoreCommentTextarea.vue'
import { useDeepBoxCommentsStore } from '@/stores/deepbox/nodes/comments'
import { useI18n } from 'vue-i18n'

const props = defineProps({
  user: {
    type: Object,
    default: undefined,
  },
  comment: {
    type: Object,
    default: () => ({}),
  },
  nodeId: {
    type: String,
    default: '',
  },
  highlight: {
    type: Boolean,
    default: false,
  },
  maxLength: {
    type: [Number, String],
    default: 1000,
  },
  rules: {
    type: Array,
    default: () => [],
  },
})

const emit = defineEmits([
  'comment:edit',
  'comment:update:prop',
  'menu:click:remove',
])

const { t } = useI18n()
const i18n = useI18n()

const deepBoxCommentsStore = useDeepBoxCommentsStore()

const commentEdit = ref('')
const isCommentFieldFocused = ref(false)
const isEditMode = ref(false)
const loadingComment = ref(false)

const menuItems = computed<MenuUserItem[]>(() => {
  const items: MenuUserItem[] = []
  if (props.comment.commentPolicy?.canEdit) {
    items.push({
      id: 'EDIT',
      title: t('core.comments.actions.edit'),
      dataTestId: 'comment-item-action-edit',
      prepend: {
        icon: 'far fa-pen',
      },
      action: async () => {
        if (props.comment.hasMore) {
          await deepBoxCommentsStore.fetchNodeComment({
            nodeId: props.nodeId,
            commentId: props.comment.commentId,
          })
        }
        startEditing()
      },
    })
  }
  // TODO: add this item once the API allows
  // { id: 'REPORT_TO_OWNER', title: 'Report to owner' },
  if (props.comment.commentPolicy?.canPurge) {
    items.push({
      id: 'DELETE',
      dataTestId: 'comment-item-action-delete',
      prepend: {
        icon: {
          icon: 'far fa-trash',
          color: 'error',
        },
      },
      title: t('core.comments.actions.delete'),
      action: () => {
        emit('menu:click:remove', props.item)
      },
    })
  }

  return items
})

const isModified = computed(() => props.comment?.modified !== null)
const modifiedTime = computed(() => props.comment?.modified.time)
const modifiedUserDisplayName = computed(
  () => props.comment?.modified?.user?.displayName,
)
const isValid = computed(
  () =>
    (commentEdit.value && commentEdit.value?.length) ||
    0 <= Number(props.maxLength),
)

function onCommentEdit() {
  emit('comment:edit', {
    commentId: props.comment.commentId,
    text: commentEdit.value,
  })
  isEditMode.value = false
}

function onChangeProp(prop) {
  emit('comment:update:prop', {
    commentId: props.comment.commentId,
    prop,
  })
}

function onEditModeCancel() {
  if (props.comment?.isEditMode) {
    onChangeProp({
      isEditMode: false,
    })
  }
}

async function loadCompleteComment() {
  loadingComment.value = true
  try {
    await deepBoxCommentsStore.fetchNodeComment({
      nodeId: props.nodeId,
      commentId: props.comment.commentId,
    })
  } catch {
    console.error('ERROR fetching comment, commentId:', props.comment.commentId)
  } finally {
    loadingComment.value = false
  }
}

function startEditing() {
  commentEdit.value = props.comment.text
  onChangeProp({
    isEditMode: !props.comment?.isEditMode,
  })
}
</script>
