import { computed, onMounted, onUnmounted, ref, watch } from 'vue'

// composables
import { useRouter } from 'vue-router'

// stores
import { useDeepBoxPreviewStore } from '@/stores/deepbox/deepboxes/preview.ts'

// types & constants
import { constants } from '@/constants'
import { useParams } from '@deepcloud/deep-ui-lib'

const preventKeyboardNavigation = ref(true)

export function usePreviewNavigation() {
  const deepBoxPreviewStore = useDeepBoxPreviewStore()

  const router = useRouter()

  const params = useParams<{
    node: string
  }>()

  onMounted(() => {
    // register keyup navigation
    document.addEventListener('keyup', navigate)
  })

  onUnmounted(() => {
    // remove keyup navigation
    document.removeEventListener('keyup', navigate)
  })

  const parentChildrenFiles = computed(
    () =>
      deepBoxPreviewStore.parentNodeChildren?.nodes?.filter(
        (node) => node.type === constants.NODE_TYPE_FILE,
      ) || [],
  )

  const currentFileIndex = computed(() =>
    parentChildrenFiles.value.findIndex(
      (node) => node.nodeId === params.value.node,
    ),
  )

  watch(
    () => currentFileIndex.value,
    (newValue) => {
      if (newValue > 0) {
        // only allow navigation if current file index exists
        preventKeyboardNavigation.value = false
      }
    },
  )

  const nextItemRoute = computed(() => {
    return {
      name: 'node-preview',
      params: {
        node: parentChildrenFiles.value[currentFileIndex.value + 1]?.nodeId,
      },
    }
  })

  const previousItemRoute = computed(() => {
    return {
      name: 'node-preview',
      params: {
        node: parentChildrenFiles.value[currentFileIndex.value - 1]?.nodeId,
      },
    }
  })

  function openNextFile() {
    if (currentFileIndex.value < parentChildrenFiles.value.length - 1) {
      router.push(nextItemRoute.value)
    }
  }

  function openPreviousFile() {
    if (previousItemRoute.value && currentFileIndex.value >= 0) {
      router.push(previousItemRoute.value)
    }
  }

  function navigate(event: KeyboardEvent) {
    if (preventKeyboardNavigation.value) {
      return
    }
    event.stopPropagation()
    if (event.key === 'ArrowRight') {
      openNextFile()
    } else if (event.key === 'ArrowLeft') {
      openPreviousFile()
    }
  }

  return {
    navigate,
    preventKeyboardNavigation,
    parentChildrenFiles,
    currentFileIndex,
    nextItemRoute,
    previousItemRoute,
  }
}
