import type { BoxEntry, BoxInfo } from '@/api/types/deepbox/box'
import { useDeepBoxCoreStore } from '@/stores/deepbox/core'
import { useDeepBoxDeepBoxesBoxesNodesStore } from '@/stores/deepbox/deepboxes/boxes/nodes.ts'

export function useBoxHelper() {
  const deepBoxCoreStore = useDeepBoxCoreStore()
  const deepBoxDeepBoxesBoxesNodesStore = useDeepBoxDeepBoxesBoxesNodesStore()

  function selectBox(box: BoxEntry) {
    if (box.boxNodeId !== deepBoxCoreStore.selectedBox?.boxNodeId) {
      deepBoxDeepBoxesBoxesNodesStore.$reset()
      deepBoxCoreStore.selectedBox = box
    }
  }

  function isSharedBox(
    box: BoxInfo | BoxEntry,
    sharedBoxes: BoxInfo[] | BoxEntry[],
    currentOrgId: string,
  ) {
    return !!sharedBoxes.find(
      (shared) =>
        shared.boxNodeId === box.boxNodeId &&
        shared.company.companyId !== currentOrgId,
    )
  }

  return {
    selectBox,
    isSharedBox,
  }
}
