import { acceptHMRUpdate, defineStore } from 'pinia'
import { constants } from '@/constants'
import helpers from '@/utils/helpers'
import type {
  UserFull,
  UserMeOrganization,
} from '@/api/types/deepadmin/users/user'
import type { InvitationPending } from '@/api/types/invitation'

import { deepAdminUsersMeAPI } from '@/api/deepadmin/users/users-me'
import { computed, ref } from 'vue'
import { deepBoxOverviewAPI } from '@/api/deepbox/overview/overview'
import type { OverviewQueryParams } from '@/api/types/deepbox/overview'
import { isPersonalOrganizationNoBox } from '@/utils/helpers/onboarding.ts'

export const useDeepAdminUsersMeStore = defineStore('deepAdminUsersMe', () => {
  const user = ref<UserFull>({} as UserFull)
  const organizations = ref<UserMeOrganization[]>([])
  const selectedOrganization = ref<UserMeOrganization>()
  const switchOrganizationPending = ref(false)
  const fetchUserOrganizationsPending = ref(false)
  const pendingInvitations = ref<InvitationPending[]>([])
  const fetchUsersMePending = ref(false)
  const fetchUserMePending = ref(false)
  const fetchUserMeFulfilled = ref(false)

  const getUserOrganizationsPersonal = computed(() => {
    const organizations: UserMeOrganization[] = user.value?.companies || []
    return organizations.filter(
      (org) =>
        org.structure &&
        constants.COMPANY_PERSONAL_STRUCTURE_TYPES.includes(org.structure),
    )
  })

  const getUserSelectableOrganizations = computed(() => {
    if (organizations.value.length === 1) {
      return organizations.value
    }

    let verifiedOrganizations = organizations.value.filter((organization) =>
      constants.VERIFIED_VERIFICATION_STATES.includes(
        organization?.verification_state,
      ),
    )
    const verificationInProgressOrganizations = organizations.value.filter(
      (organization) =>
        organization.verification_process_status ===
        constants.VERIFICATION_PROCESS_TYPE_IN_PROGRESS,
    )
    const verifiedPersonalOrganizations = verifiedOrganizations.filter(
      (organization) => helpers.isPersonalStructure(organization),
    )
    const verifiedBusinessOrganizations = verifiedOrganizations.filter(
      (organization) => !helpers.isPersonalStructure(organization),
    )
    verifiedOrganizations = verifiedBusinessOrganizations.concat(
      verifiedPersonalOrganizations,
    )
    const unverifiedOrganizations = organizations.value.filter(
      (organization) =>
        organization?.verification_state === constants.VERIFICATION_STATE_NONE,
    )
    const unverifiedPersonalOrganizations = unverifiedOrganizations.filter(
      (organization) => helpers.isPersonalStructure(organization),
    )
    const unverifiedBusinessOrganizations = unverifiedOrganizations.filter(
      (organization) => !helpers.isPersonalStructure(organization),
    )

    let selectableOrganizations: UserMeOrganization[] = []
    if (verifiedOrganizations.length > 0) {
      selectableOrganizations = verifiedOrganizations.concat(
        unverifiedBusinessOrganizations,
      )
    } else {
      selectableOrganizations = unverifiedBusinessOrganizations.concat(
        selectableOrganizations,
      )
    }

    unverifiedPersonalOrganizations.forEach((organization) => {
      if (
        organization.hasSharedBoxes ||
        (verifiedOrganizations.length === 0 &&
          verificationInProgressOrganizations.length > 0)
      ) {
        selectableOrganizations.push(organization)
      }
    })
    return selectableOrganizations
  })

  function getUserPersonalOrg(organizations: UserMeOrganization[]) {
    return organizations.filter((org) =>
      org.roles.some((r) => [constants.USER_ROLE_MANAGE_COMPANY].includes(r)),
    )
  }

  const personalOrganization = computed(() => {
    let list = getUserOrganizationsPersonal.value as UserMeOrganization[]

    list = getUserPersonalOrg(list)

    return list.length > 0 ? list[0] : null
  })

  const _isPersonalOrganizationNoBox = computed(() => {
    const userPersonalOrganization =
      personalOrganization.value as UserMeOrganization | null

    return userPersonalOrganization
      ? isPersonalOrganizationNoBox(userPersonalOrganization)
      : undefined
  })

  const currentOrganization = computed(() => {
    return organizations.value.find(
      (organization) =>
        organization.group_id === selectedOrganization.value?.group_id,
    )
  })

  const getOrganizationById = (id: string) => {
    return organizations.value.find(
      (organization) => organization.group_id === id,
    )
  }

  const getUserSelectableOrganizationById = (id: string) => {
    return getUserSelectableOrganizations.value.find(
      (organization) => organization.group_id === id,
    )
  }

  const selectedOrganizationId = computed(
    () => selectedOrganization.value?.group_id,
  )

  async function fetchUsersMe() {
    fetchUsersMePending.value = true
    try {
      const res = await deepAdminUsersMeAPI.get()
      const resData = res?.data
      user.value = resData
      pendingInvitations.value = resData?.pending_invitations
      fetchUsersMePending.value = false
      return Promise.resolve(res)
    } catch (error) {
      console.log(error)
      return Promise.reject(error)
    } finally {
      fetchUsersMePending.value = false
    }
  }

  async function fetchUserOrganizations() {
    fetchUserOrganizationsPending.value = true
    try {
      const res = await fetchUsersMe()
      const resData = res?.data

      // Check if personal organization need to be filtered
      const userOrganizations = [...resData.companies]
      // update state
      organizations.value = [...userOrganizations]
      let userPersonalOrganizations = userOrganizations.filter((userOrg) => {
        return (
          userOrg.structure &&
          constants.COMPANY_PERSONAL_STRUCTURE_TYPES.includes(userOrg.structure)
        )
      })
      // filter user no-box org if is verified and if the structure still be no-box
      let userPersonalOrganization: UserMeOrganization | null = null

      // filter all other personal org out ( users can only have 1 personal with role
      userPersonalOrganizations = getUserPersonalOrg(userPersonalOrganizations)

      if (userPersonalOrganizations.length === 1) {
        userPersonalOrganization =
          userPersonalOrganizations[0] as UserMeOrganization
      }

      if (userPersonalOrganization !== null) {
        if (
          _isPersonalOrganizationNoBox.value &&
          userOrganizations.length > 1
        ) {
          const personalOrgIdx = userOrganizations.findIndex(
            (userOrg) =>
              userOrg.group_id === userPersonalOrganization?.group_id,
          )
          if (personalOrgIdx !== -1) {
            const params: OverviewQueryParams = {
              'deepBoxes.boxes.limit': 1,
              'companyId': userPersonalOrganization.group_id,
            }

            const userPersonalOrganizationOverview =
              await deepBoxOverviewAPI.get(params)
            const userPersonalOrganizationSharesBox =
              userPersonalOrganizationOverview?.data?.sharedWithMe?.boxes || []
            userOrganizations[personalOrgIdx].hasSharedBoxes =
              userPersonalOrganizationSharesBox.length > 0
            userOrganizations[personalOrgIdx].hasPrivateBox =
              userPersonalOrganizationOverview.data.privateBox !== undefined
          }
        }
      }
      organizations.value = [...userOrganizations]

      return Promise.resolve(res)
    } catch (error) {
      console.log(error)
      return Promise.reject(error)
    } finally {
      fetchUserOrganizationsPending.value = false
    }
  }

  function selectOrganization(organization: UserMeOrganization) {
    selectedOrganization.value = { ...organization }
  }

  return {
    // state
    user,
    organizations,
    selectedOrganization,
    selectedOrganizationId,
    switchOrganizationPending,
    fetchUserOrganizationsPending,
    pendingInvitations,
    fetchUsersMePending,
    fetchUserMePending,
    fetchUserMeFulfilled,
    // getters
    getUserOrganizationsPersonal,
    getUserSelectableOrganizations,
    personalOrganization,
    currentOrganization,
    getOrganizationById,
    getUserSelectableOrganizationById,
    // actions
    fetchUsersMe,
    fetchUserOrganizations,
    selectOrganization,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useDeepAdminUsersMeStore, import.meta.hot),
  )
}
