<template>
  <v-list class="payment-history-list py-0" density="compact">
    <v-list-group
      v-for="(item, index) in items"
      :key="index"
      class="payment-history-list-group mb-3 rounded-sm"
    >
      <template #activator="{ props }">
        <v-defaults-provider
          :defaults="{
            VIcon: {
              size: '11',
            },
          }"
        >
          <v-list-item v-bind="props" class="pl-1 pr-2" rounded slim>
            <div class="d-flex">
              <div class="payment-history-list-timestamp">
                {{ formatDateTimeWithoutSeconds(item.createdAt) }}
              </div>
              <v-spacer />
              <v-chip
                class="mb-1"
                :color="getStatusColor(item.paymentStatus)"
                density="compact"
                size="small"
              >
                {{ getStatusText(item.paymentStatus) }}
              </v-chip>
            </div>
            <div class="d-flex align-center ga-2">
              <div class="text-truncate">{{ item.destination.name }}</div>
              <v-spacer />
              <div class="text-no-wrap">
                {{ item.paymentCurrency }} {{ item.paymentAmount }}
              </div>
            </div>
          </v-list-item>
        </v-defaults-provider>
      </template>
      <v-list-item class="payment-history-list-expanded">
        <div class="mt-2 d-flex align-start ga-2">
          <div>
            <div class="text-medium-emphasis">
              {{ t('deeppay.payments_history.list.destination') }}:
            </div>
            <div class="text-pre">
              {{ getAddressLines(item.destination).join('\n') }}
            </div>
            <div class="mt-1">
              {{ item.destination.accountIban }}
            </div>
          </div>
          <v-spacer />
          <div>
            <DeepPayPaymentsHistoryByAddressDialog
              :box-id="boxId"
              :destination="item.destination"
              :origin-organization-id="originOrganizationId"
              :type-id="typeId"
            >
              <template #activator="{ props: activatorProps }">
                <v-btn
                  v-tooltip="{
                    text: 'Zahlungen an diesen Empfänger',
                    location: 'top',
                    openDelay: 300,
                  }"
                  class="mt-1 mr-3"
                  color="primary"
                  icon
                  size="24"
                  slim
                  variant="tonal"
                  v-bind="activatorProps"
                >
                  <v-icon size="10">far fa-list</v-icon>
                </v-btn>
              </template>
            </DeepPayPaymentsHistoryByAddressDialog>
          </div>
        </div>
        <div>
          <div></div>
        </div>
        <div class="mt-4 text-medium-emphasis">
          {{ t('deeppay.payments_history.list.created_by') }}:
        </div>
        <div class="mb-3">{{ item.createdByName }}</div>
      </v-list-item>
      <v-divider v-if="index < items.length - 1" />
    </v-list-group>
  </v-list>
</template>

<script setup lang="ts">
import { type PropType } from 'vue'
import type { PaymentHistory } from '@/api/types/deepo/payments-history.ts'
import { useI18n } from 'vue-i18n'
import { useDeepPayPaymentsHistory } from './use-deep-pay-payments-history.ts'
import DeepPayPaymentsHistoryByAddressDialog from './DeepPayPaymentsHistoryByAddressDialog.vue'
import { formatDateTimeWithoutSeconds } from '@deepcloud/deep-ui-lib'

defineProps({
  items: {
    type: Array as PropType<PaymentHistory[]>,
    default: () => [],
  },
  originOrganizationId: {
    type: String,
    required: true,
  },
  typeId: {
    type: String,
    required: true,
  },
  boxId: {
    type: String,
    required: true,
  },
})

const { t } = useI18n()
const { getStatusColor, getStatusText, getAddressLines } =
  useDeepPayPaymentsHistory()
</script>

<style scoped lang="scss">
.payment-history-list div {
  font-size: 13px;
  line-height: 22px;
}

.payment-history-list-timestamp {
  font-size: 12px !important;
}

.payment-history-list-expanded {
  padding-inline-start: 4px !important;
}
</style>
