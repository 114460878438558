<template>
  <v-menu
    v-if="deepBoxDeepBoxesBoxesFavoritesStore.favorites.length > 0 && smAndUp"
    v-model="favoritesMenu"
    :close-on-content-click="false"
    location="bottom"
  >
    <template #activator="{ props: ActivatorProps }">
      <v-btn v-bind="ActivatorProps" :class="mdAndUp ? 'pa-1 px-2' : 'pa-0'">
        <div
          v-if="mdAndUp"
          class="fav-recent-menu-btn"
          data-test-id="favorite-boxes"
        >
          {{ t('main_view.favorites') }}
        </div>
        <v-icon v-else>fas fa-star</v-icon>
        <v-icon :class="device?.isTablet ? 'ml-0' : 'ml-1'">
          {{ favoritesMenu ? 'far fa-angle-up' : 'far fa-angle-down' }}
        </v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-for="item in deepBoxDeepBoxesBoxesFavoritesStore.favorites"
        :key="item.boxNodeId"
        class="pr-2 pl-4"
      >
        <div style="width: 275px">
          <BoxItemLink
            :box="item"
            :data-test-id="`favorite-${item.boxName}`"
            is-favourite
            :is-shared="
              boxHelper.isSharedBox(item, sharedBoxes, organizationId)
            "
            show-favorite
            @click:box-item="selectBox(item)"
          />
        </div>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script lang="ts" setup>
import { inject, PropType, ref, watch } from 'vue'
import BoxItemLink from '@/components/box/BoxItemLink.vue'
import { useDeepBoxDeepBoxesBoxesFavoritesStore } from '@/stores/deepbox/deepboxes/boxes/favorites'
import { useBoxHelper } from '@/components/appbar/use-box-helper'
import type { BoxEntry } from '@/api/types/deepbox/box'
import { DeviceKey } from '@/plugins/device-detector-js.ts'
import { useI18n } from 'vue-i18n'
import { useDisplay } from 'vuetify'

const props = defineProps({
  organizationId: {
    type: String,
    required: true,
  },
  favoriteBoxes: {
    type: Array as PropType<BoxEntry[]>,
    required: true,
  },
  sharedBoxes: {
    type: Array as PropType<BoxEntry[]>,
    required: true,
  },
})

const { t } = useI18n()
const { smAndUp, mdAndUp } = useDisplay()

const device = inject(DeviceKey)

const deepBoxDeepBoxesBoxesFavoritesStore =
  useDeepBoxDeepBoxesBoxesFavoritesStore()

const favoritesMenu = ref(false)

const boxHelper = useBoxHelper()

function selectBox(box: BoxEntry) {
  boxHelper.selectBox(box)
  favoritesMenu.value = false
}

// TODO: remove this when Store dependency was removed
watch(
  () => props.favoriteBoxes,
  (newValue) => {
    if (newValue) {
      deepBoxDeepBoxesBoxesFavoritesStore.favorites = newValue
    }
  },
  { immediate: true },
)
</script>

<style scoped lang="scss">
.v-menu__content {
  border-radius: 12px !important;
}
</style>
