<template>
  <v-img
    v-if="node && isNodeTypeFolder(node)"
    height="136"
    src="/svg/folder/icon-folder.svg"
    width="50"
  />
  <v-card
    v-else-if="nodeThumbnailUrl"
    class="text-center align-center justify-center"
    elevation="8"
    :height="height"
    :max-height="maxHeight"
    :max-width="maxWidth"
    :min-height="minHeight"
    :min-width="minWidth"
    :width="width"
  >
    <v-card-text class="align-center text-center pa-0 ma-0">
      <v-card class="pa-0 ma-0" flat>
        <v-img v-if="nodeThumbnailUrl" :src="nodeThumbnailUrl">
          <template #placeholder>
            <v-row align="center" class="fill-height ma-0" justify="center">
              <v-progress-circular color="primary-lighten-5" indeterminate />
            </v-row>
          </template>
        </v-img>
      </v-card>
    </v-card-text>
  </v-card>
  <v-img
    v-else
    height="136"
    :src="`/svg/files/${getNodeIcon(node)}-20-px.svg`"
    width="50"
  />
</template>

<script lang="ts" setup>
import { computed, type PropType } from 'vue'
import { useNode } from '@/composables/use-node'
import type { Node, NodeInfo } from '@/api/types/deepbox/node'
import type { PathSegment } from '@/api/types/deepbox/path'
import { getNodeIcon } from '@/utils/helpers/nodes.ts'

const props = defineProps({
  node: {
    type: Object as PropType<Node | PathSegment | NodeInfo>,
    default: () => ({}),
  },
  height: {
    type: [Number, String],
    default: 200,
  },
  maxHeight: {
    type: [Number, String],
    default: null,
  },
  minHeight: {
    type: [Number, String],
    default: null,
  },
  width: {
    type: [Number, String],
    default: null,
  },
  maxWidth: {
    type: [Number, String],
    default: null,
  },
  minWidth: {
    type: [Number, String],
    default: null,
  },
})

const { isNodeTypeFolder } = useNode()

const nodeThumbnailUrl = computed(() => props.node.thumbnailUrl || null)
</script>
