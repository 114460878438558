<!--
  THIS COMPONENT IS CURRENTLY ONLY USED FOR DEV PURPOSES
-->

<template>
  <v-navigation-drawer
    ref="drawerRef"
    v-model="showDrawer"
    disable-route-watcher
    location="right"
    temporary
    :touchless="false"
    width="350"
  >
    <v-toolbar class="text-no-wrap" flat title="DeepCloud News">
      <v-btn icon="$close" @click="showDrawer = false"></v-btn>
    </v-toolbar>
    <template v-if="news.length > 0">
      <div class="d-flex justify-center align-center pa-2">
        <v-btn-toggle v-model="displayAs" density="compact">
          <v-btn icon="far fa-square" value="card"></v-btn>
          <v-btn icon="far fa-list" value="list"></v-btn>
        </v-btn-toggle>
      </div>

      <div class="overflow-y-auto" style="height: calc(100svh - 64px - 52px)">
        <template v-if="displayAs === 'card'">
          <v-card
            v-for="newsItem in news"
            :key="newsItem.uuid"
            class="ma-4"
            rounded="xl"
            variant="outlined"
            @click="currentNewsItem = newsItem"
          >
            <v-card-title>
              <v-icon
                v-if="newsItem.category"
                class="close-popup"
                color="primary"
                elevation="0"
                style="
                  position: absolute !important;
                  right: 20px;
                  top: 20px;
                  transform: translate(50%, -50%);
                  z-index: 1;
                "
              >
                far fa-newspaper
              </v-icon>
              <span v-if="getCurrentTranslationByItem(newsItem)">
                {{ getCurrentTranslationByItem(newsItem).title }}
              </span>
              <span v-else class="text-error"> - </span>
            </v-card-title>
            <v-card-subtitle v-if="getCurrentTranslationByItem(newsItem)">
              <span v-if="getCurrentTranslationByItem(newsItem)">
                {{ getCurrentTranslationByItem(newsItem).lead_text }}
              </span>
              <span v-else class="text-error">
                Not available on lang: {{ locale }}
              </span>
            </v-card-subtitle>
            <v-card-text v-if="getCurrentTranslationByItem(newsItem)">
              <v-img
                v-if="getCurrentNewsImage(newsItem)"
                class="rounded-lg"
                cover
                :lazy-src="getCurrentNewsImage(newsItem)"
                max-height="170"
                max-width="300"
                :src="getCurrentNewsImage(newsItem)"
              />
              <CoreReadMoreOrLess
                class="mt-2"
                :content="
                  getSanitizedHtml(getCurrentTranslationByItem(newsItem)?.text)
                "
              />
            </v-card-text>
          </v-card>
        </template>
        <template v-else-if="displayAs === 'list'">
          <v-list lines="three">
            <!-- TODO(VUE-3-MIGRATION): was in vue-2 `outlined` -->
            <v-sheet
              v-for="newsItem in news"
              :key="newsItem.uuid"
              class="ma-4"
              rounded="xl"
            >
              <v-list-item @click="currentNewsItem = newsItem">
                <template #prepend>
                  <v-avatar tile>
                    <v-img
                      v-if="getCurrentNewsImage(newsItem)"
                      class="rounded-lg"
                      cover
                      :lazy-src="getCurrentNewsImage(newsItem)"
                      max-height="170"
                      max-width="300"
                      :src="getCurrentNewsImage(newsItem)"
                    />
                  </v-avatar>
                </template>
                <v-list-item-subtitle v-if="newsItem.category">
                  <v-icon v-if="newsItem.category === 'news'" color="primary">
                    far fa-newspaper
                  </v-icon>
                </v-list-item-subtitle>
                <v-list-item-title class="text-h6">
                  <span v-if="getCurrentTranslationByItem(newsItem)">
                    {{ getCurrentTranslationByItem(newsItem).title }}
                  </span>
                  <span v-else class="text-error"> - </span>
                </v-list-item-title>
                <v-list-item-subtitle
                  v-if="getCurrentTranslationByItem(newsItem)"
                >
                  <span v-if="getCurrentTranslationByItem(newsItem)">
                    {{ getCurrentTranslationByItem(newsItem).lead_text }}
                  </span>
                  <span v-else class="text-error">
                    Not available on lang: {{ locale }}
                  </span>
                </v-list-item-subtitle>
                <div
                  v-if="getCurrentTranslationByItem(newsItem)"
                  class="text-body-2 text-high-emphasis"
                >
                  <CoreReadMoreOrLess
                    :content="
                      getSanitizedHtml(
                        getCurrentTranslationByItem(newsItem)?.text,
                      )
                    "
                  />
                </div>
              </v-list-item>
            </v-sheet>
          </v-list>
        </template>
      </div>
      <NotificationsNewsDialog
        v-if="currentNewsItem"
        :news-item="currentNewsItem"
        @close="currentNewsItem = undefined"
      />
    </template>
    <template v-else>
      <div class="text-center">No news 😔</div>
    </template>
  </v-navigation-drawer>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import type { VNavigationDrawer } from 'vuetify/components'
import { deepAdminNotificationsNewsAPI } from '@/api/deepadmin/notifications/news'
import type { News } from '@/api/types/deepadmin/notifications/news'
import { useNotificationsNews } from '@/components/notifications/use-notifications-news'
import NotificationsNewsDialog from '@/components/notifications/NotificationsNewsDialog.vue'
import { useI18n } from 'vue-i18n'
import CoreReadMoreOrLess from '@/components/core/CoreReadMoreOrLess.vue'
import { getSanitizedHtml } from '@deepcloud/deep-ui-lib'

const { locale } = useI18n()

const news = ref<News[]>([])
const currentNewsItem = ref<News | undefined>(undefined)
type DISPLAY_AS = 'card' | 'list'
const displayAs = ref<DISPLAY_AS>('card')

const {
  getCurrentTranslationByItem,
  getCurrentNewsImage,
  showDrawer: showDrawerState,
} = useNotificationsNews()

const showDrawer = computed({
  get() {
    return showDrawerState.value
  },
  set(value) {
    showDrawerState.value = value
  },
})

async function onFetchAllNews() {
  try {
    const { data } = await deepAdminNotificationsNewsAPI.get({ all: true })
    news.value = data
  } catch (e) {
    console.log(e)
  }
}

watch(
  () => showDrawer.value,
  (newValue) => {
    if (newValue) {
      onFetchAllNews()
    }
  },
)
</script>
