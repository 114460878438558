<template>
  <v-navigation-drawer
    ref="drawerRef"
    v-model="settingsDevStore.devShowSettings"
    class="dev-settings-drawer"
    disable-route-watcher
    location="right"
    temporary
    :touchless="false"
    :width="drawerWidth"
  >
    <v-toolbar flat height="52">
      <v-app-bar-nav-icon v-if="smAndUp">
        <v-btn
          :icon="isDefaultWidth ? 'far fa-angles-left' : 'far fa-angles-right'"
          @click="onSetToggleWidth"
        >
        </v-btn>
      </v-app-bar-nav-icon>
      <v-spacer v-if="!isDefaultWidth" />
      <v-toolbar-title>
        <span class="text-h6"> DeepBox DEV Settings </span>
      </v-toolbar-title>
      <v-spacer v-if="!isDefaultWidth" />
      <v-btn icon="$close" @click="settingsDevStore.devShowSettings = false">
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <v-switch
        v-model="settingsDevStore.devModeEnabled"
        color="success"
        density="compact"
        false-icon="$close"
        hide-details
        inset
        label="DevMode Enabled"
        true-icon="far fa-check"
      >
      </v-switch>
      <span class="text-caption"> Enable or disable the dev mode </span>

      <v-divider class="ma-4" />

      <v-form :disabled="!settingsDevStore.devModeEnabled">
        <div v-for="feature in featuresItems" :key="feature.id">
          <v-switch
            v-model="settingsDevStore.devModeFeatures"
            color="success"
            density="compact"
            false-icon="$close"
            hide-details
            inset
            :label="feature.text"
            multiple
            true-icon="far fa-check"
            :value="feature.value"
          >
            <template #label>
              <div class="flex-grow-1">
                {{ feature.text }}
              </div>

              <DeepStageLabel
                v-if="feature.flag"
                class="ml-1"
                :flag="feature.flag"
                icon-display-as-fallback="chip"
                size="x-small"
              />
            </template>
          </v-switch>
          <span v-if="feature.description" class="text-caption">
            {{ feature.description }}
          </span>
          <v-form
            v-if="feature.settings && feature.settings.length > 0"
            class="pl-8"
            :disabled="
              !settingsDevStore.devModeFeatures.includes(feature.value) ||
              !settingsDevStore.devModeEnabled
            "
          >
            <template
              v-for="featureSetting in feature.settings"
              :key="featureSetting.id"
            >
              <v-checkbox
                color="primary"
                density="compact"
                hide-details
                :label="featureSetting.text"
                :model-value="featureSetting.value"
                @update:model-value="featureSetting.click"
              >
              </v-checkbox>
              <span v-if="featureSetting.description" class="text-caption">
                {{ featureSetting.description }}
              </span>
            </template>
          </v-form>
        </div>
        <v-switch
          v-model="settingsDevStore.devDebugEnabled"
          color="success"
          density="compact"
          false-icon="$close"
          hide-details
          inset
          label="Debug Enabled"
          true-icon="far fa-check"
        >
        </v-switch>
        <span class="text-caption"> Enables debug infos on UI </span>
        <v-switch
          class="me-auto"
          color="success"
          density="compact"
          false-icon="far fa-sun-bright"
          hide-details
          inset
          :label="`Theme: ${appTheme.isDark() ? 'dark' : 'light'}`"
          :model-value="appTheme.isDark()"
          true-icon="far fa-moon"
          @update:model-value="appTheme.toggleTheme()"
        >
        </v-switch>
        <span class="text-caption"> Switch theme to dark/light </span>
        <template v-if="isLocalEnv()">
          <v-divider class="ma-4" />
          <div class="text-body-2">Theme</div>
          <v-list-subheader>Local only</v-list-subheader>
          <div class="d-flex">
            <div class="d-flex">
              <v-menu :close-on-content-click="false" z-index="2501">
                <template #activator="{ props: ActivatorProps }">
                  <v-btn color="primary" v-bind="ActivatorProps">
                    Primary Color
                  </v-btn>
                </template>
                <v-card>
                  <v-card-text>
                    <v-color-picker
                      v-model="theme.current.value.colors.primary"
                    ></v-color-picker>
                  </v-card-text>
                </v-card>
              </v-menu>
            </div>
          </div>
        </template>
      </v-form>
    </v-card-text>

    <template #append>
      <v-card-text>
        <v-divider />
        <div
          v-for="releaseInfo in releaseInfos"
          :key="releaseInfo.title"
          class="text-medium-emphasis text-caption py-2 px-3 d-flex align-center"
        >
          <div class="d-inline-flex align-center">
            <v-icon size="small" start>{{ releaseInfo.icon }}</v-icon>
            {{ releaseInfo.title }}
          </div>
          <v-btn
            v-if="releaseInfo.value"
            class="text-none px-2 ms-auto"
            color="primary"
            density="compact"
            :loading="
              copyToClipboard.isCopyToClipboardLoading.value &&
              valueToCopyToClipboard === releaseInfo.value
            "
            rounded
            variant="text"
            @click="
              onCopyValueToClipboard(
                releaseInfo.valueToCopy
                  ? releaseInfo.valueToCopy
                  : releaseInfo.value,
              )
            "
          >
            {{ releaseInfo.value }}
          </v-btn>
        </div>
      </v-card-text>
    </template>
  </v-navigation-drawer>
</template>

<script setup lang="ts">
import { computed, inject, onMounted, onUnmounted, ref, watch } from 'vue'
import { useSettingsDevStore } from '@/stores/settings/settings-dev'
import {
  DeepStageLabel,
  formatDateTimeByDefaultFormat,
} from '@deepcloud/deep-ui-lib'
import { useLocalStorage } from '@vueuse/core'
import { useAppTheme } from '@/composables/use-app-theme.ts'
import { useDisplay, useTheme } from 'vuetify'
import { isLocalEnv } from '@/utils/helpers/env.ts'
import { useCopyToClipboard } from '@/composables/use-copy-to-clipboard'
import { useKeycloak } from '@/composables/auth/use-keycloak.ts'
import { DeviceKey } from '@/plugins/device-detector-js.ts'
import { isDeepAEnabled } from '@/utils/helpers/features.ts'

const appTheme = useAppTheme()
const theme = useTheme()

const settingsDevStore = useSettingsDevStore()
// always close drawer on load
settingsDevStore.devShowSettings = false

const DRAWER_DEFAULT_WIDTH = 400
const drawerWidth = ref(DRAWER_DEFAULT_WIDTH)

const isDefaultWidth = computed(
  () => drawerWidth.value === DRAWER_DEFAULT_WIDTH,
)

function resetDrawerWidth() {
  drawerWidth.value = DRAWER_DEFAULT_WIDTH
}

function onSetToggleWidth() {
  if (isDefaultWidth.value) {
    drawerWidth.value = window.innerWidth
  } else {
    resetDrawerWidth()
  }
}

const { xs, smAndUp } = useDisplay()
watch(
  () => settingsDevStore.devShowSettings,
  (newValue) => {
    if (newValue) {
      if (xs.value) {
        drawerWidth.value = window.innerWidth
      } else {
        resetDrawerWidth()
      }
    } else {
      resetDrawerWidth()
    }
  },
)

const doActiveApiPolling = useLocalStorage('activeDeepFlowTaskPolling', false)

const { bearer } = useKeycloak()

const featuresItems = computed(() =>
  [
    {
      id: 'DEEP_FLOW',
      text: 'DeepFlow',
      value: 'DEEP_FLOW',
      flag: 'DEV',
      description:
        'Enable DeepFlow Tasks Menu on AppBar and option on files context menu inside the box view',
      settings: [
        {
          id: 'test-1',
          text: 'API Task polling',
          value: doActiveApiPolling.value,
          description: 'Enables active tasks polling from the API',
          click: () => {
            doActiveApiPolling.value = !doActiveApiPolling.value
          },
        },
      ],
    },
    {
      id: 'DEEP_A',
      text: 'DeepA',
      value: 'DEEP_A',
      flag: 'DEV',
      description:
        'Enables DeepA Features like: Support Chat or files action (context menu) like summarize or send to DeepA',
      show: () => isDeepAEnabled(),
    },
    {
      id: 'NEWS_LIST',
      text: 'News list',
      value: 'NEWS_LIST',
      flag: 'DEV',
      description: 'Enables option on the user menu to see all news',
    },
  ].filter((f) => (f.show ? f.show() : true)),
)

const releaseInfos = computed(() => [
  {
    title: 'Environment',
    icon: 'far fa-code',
    value: import.meta.env.VITE_ENVIRONMENT,
  },
  {
    title: 'Tag',
    icon: 'far fa-tag',
    value: import.meta.env.VITE_RELEASE_TAG,
  },
  {
    title: 'Datetime',
    icon: 'far fa-clock',
    value: formatDateTimeByDefaultFormat(import.meta.env.VITE_RELEASE_DATETIME),
  },
  {
    title: 'Commit ID',
    icon: 'far fa-code-commit',
    value: import.meta.env.VITE_RELEASE_COMMIT_ID,
  },
  {
    title: 'Auth Token',
    icon: 'far fa-coin',
    value: bearer.value ? `${bearer.value?.substring(0, 10)}...` : '',
    valueToCopy: bearer.value,
  },
])

const copyToClipboard = useCopyToClipboard()

const valueToCopyToClipboard = ref('')

async function onCopyValueToClipboard(value: string) {
  valueToCopyToClipboard.value = value
  await copyToClipboard.copyToClipboard(value)
  valueToCopyToClipboard.value = ''
}

const device = inject(DeviceKey)

function onKeyDown(e: KeyboardEvent) {
  let keyCommandMatch
  if (device?.isMacOS) {
    keyCommandMatch = e.metaKey && e.key === 'd'
  } else {
    keyCommandMatch = e.ctrlKey && e.key === 'd'
  }
  if (keyCommandMatch) {
    settingsDevStore.devShowSettings = !settingsDevStore.devShowSettings
    e.preventDefault()
  }
}

onMounted(() => window.addEventListener('keydown', onKeyDown))
onUnmounted(() => window.removeEventListener('keydown', onKeyDown))
</script>

<style scoped lang="scss">
.dev-settings-drawer {
  // show drawer over dialogs
  // 2500 the nr is the default z-index of VOverlay + 1
  z-index: 2501 !important;
}
</style>
