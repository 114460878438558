<template>
  <v-container v-if="error" class="pa-md-12">
    {{ error.img }}
    <v-empty-state v-if="error && error.deepBoxError === true">
      <template v-if="error.img || error.imgComponent || error.icon" #media>
        <component :is="error.imgComponent" v-if="error.imgComponent" />
        <v-img v-else-if="error.img" v-bind="error.img" />
        <v-icon v-else v-bind="error.icon" />
      </template>
      <template v-if="error.title" #headline>
        <div v-if="error.title" class="text-h4">
          <span v-if="te(error.title)">
            {{ t(error.title) }}
          </span>
          <span v-else>
            {{ error.title }}
          </span>
        </div>
      </template>
      <template
        v-if="error.description || (!error.hideStatusCode && error.statusCode)"
        #title
      >
        <p
          v-if="error.description"
          class="mt-3"
          v-html="getSanitizedHtml(t(error.description))"
        ></p>
        <div v-else-if="!error.hideStatusCode && error.statusCode" class="mt-6">
          {{ t('error.code') + ': ' + error.statusCode }}
        </div>
      </template>

      <template v-if="error.message" #text>
        <div
          v-if="te(error.message)"
          v-html="getSanitizedHtml(t(error.message))"
        ></div>
        <div v-else v-html="getSanitizedHtml(error.message)"></div>
      </template>

      <template #actions>
        <VBtnPrimary
          v-if="error.btn"
          :href="'onClick' in error.btn ? undefined : homeUrl"
          :text="error.btn.text || t('error.home')"
          v-bind="error.btn"
        />
        <VBtnPrimary v-else color="primary" :href="homeUrl" :text="t('home')" />
      </template>
    </v-empty-state>
  </v-container>
</template>

<script setup lang="ts">
import { computed, PropType } from 'vue'
import { getSanitizedHtml } from '@deepcloud/deep-ui-lib'
import { DeepBoxError } from '@/models'
import { useI18n } from 'vue-i18n'
import { type UserMeOrganization } from '@/api/types/deepadmin/users/user.ts'

const props = defineProps({
  error: {
    type: Object as PropType<DeepBoxError>,
    default: undefined,
  },
  organization: {
    type: Object as PropType<UserMeOrganization>,
    default: undefined,
  },
})

const { t, te } = useI18n()

const homeUrl = computed(() => {
  let href = '/'
  if (props.organization?.group_id) {
    href += props.organization.group_id
  }

  return href
})
</script>
