<template>
  <LayoutDefault>
    <ContainerError :error="error" :organization="organization" />
  </LayoutDefault>
</template>

<script lang="ts" setup>
import { computed, PropType } from 'vue'
import * as Sentry from '@sentry/vue'
import LayoutDefault from '@/layouts/LayoutDefault.vue'
import { useI18n } from 'vue-i18n'
import { useHead } from '@unhead/vue'
import ContainerError from '@/components/containers/ContainerError.vue'
import { isSentryEnabled } from '@/utils/helpers/env.ts'
import { DeepBoxError } from '@/models'
import type { UserMeOrganization } from '@/api/types/deepadmin/users/user.ts'

const props = defineProps({
  error: {
    type: Object as PropType<DeepBoxError | Error>,
    default: undefined,
  },
  organization: {
    type: Object as PropType<UserMeOrganization>,
    default: undefined,
  },
})

const { t, te } = useI18n()

const isDeepBoxError = computed(() => props.error?.deepBoxError)

useHead(() => {
  let title = ''
  if (isDeepBoxError.value && props.error instanceof DeepBoxError) {
    if (props.error?.message) {
      title = te(props.error?.message)
        ? t(props.error.message)
        : props.error.message
    }
  } else if (!props.error?.message) {
    title =
      props.error?.statusCode === 404
        ? t('error.page_not_found')
        : t('error.error_occurred')
  } else {
    title = props.error.message
  }
  return {
    title,
  }
})

if (!isDeepBoxError.value) {
  let objError
  if (props.error?.data) {
    objError = new Error(props.error?.data)
  } else if (props.error?.message) {
    objError = new Error(props.error?.message)
  } else {
    objError = new Error(props.error)
  }
  if (isSentryEnabled()) {
    Sentry.captureException(objError)
  }
}
</script>
