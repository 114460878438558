import { h } from 'vue'
import { type MsgBoxOptions, useMsgBox } from '@deepcloud/deep-ui-lib'
import { useI18n } from 'vue-i18n'
import {
  type LogEntry,
  type LogEntryAvScanErrors,
  LogEntryLevelEnum,
} from '@/api/types/deepbox/log-entry.ts'
import NodeVirusTable from '@/components/node/NodeVirusTable.vue'
import { constants } from '@/constants'

export function useMsgBoxVirus() {
  const deepMsgBox = useMsgBox()
  const { t } = useI18n()

  function createNodesTable(items: LogEntry[], content: string) {
    return h(NodeVirusTable, { items, content })
  }

  function getMsgBoxTypeByLogEntryLevel(logEntryLevel: LogEntryLevelEnum) {
    switch (logEntryLevel) {
      case 'error':
        return 'error'
      case 'warn':
        return 'warning'
      case 'info':
        return 'info'
      default:
        return 'warning'
    }
  }

  async function showMsgBoxVirus(
    msgBoxOptions: Partial<MsgBoxOptions>,
    level: LogEntryLevelEnum,
  ) {
    return deepMsgBox[getMsgBoxTypeByLogEntryLevel(level)](msgBoxOptions)
      .then(({ action, checkbox }) => {
        return Promise.resolve({ action, checkbox })
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  // PREVIEW

  function showMsgBoxPreviewAvScanVirusFound(logEntry: LogEntry) {
    const msgOptions: MsgBoxOptions = {
      title: t('node.preview.errors.av_scan_virus_found.title'),
      btnActions: {
        confirm: {
          text: t('node.preview.errors.av_scan_virus_found.btn.agree'),
        },
      },
      contentComponent: createNodesTable(
        [logEntry],
        t('node.preview.errors.av_scan_virus_found.message'),
      ),
      msgBoxType: 'checkbox',
      msgBoxTypeFormFieldProps: {
        label: t('node.preview.errors.av_scan_virus_found.checkbox.label'),
      },
    }
    return showMsgBoxVirus(msgOptions, logEntry.level)
  }

  function showMsgBoxPreviewAvScanFileToBig(logEntry: LogEntry) {
    const msgOptions: MsgBoxOptions = {
      title: t('node.preview.errors.av_scan_file_too_big.title'),
      btnActions: {
        confirm: {
          text: t('node.preview.errors.av_scan_file_too_big.btn.agree'),
        },
      },
      contentComponent: createNodesTable(
        [logEntry],
        t('node.preview.errors.av_scan_file_too_big.message'),
      ),
      msgBoxType: 'checkbox',
      msgBoxTypeFormFieldProps: {
        label: t('node.preview.errors.av_scan_file_too_big.checkbox.label'),
      },
    }

    return showMsgBoxVirus(msgOptions, logEntry.level)
  }

  // DOWNLOAD

  function showMsgBoxVirusOnDownload(logEntries: LogEntry[]) {
    const errorMessageIds: LogEntryAvScanErrors[] = []
    logEntries.forEach((log) => {
      if (!errorMessageIds.includes(log.messageId)) {
        errorMessageIds.push(log.messageId)
      }
    })
    const errorMessageIdsLength = errorMessageIds.length
    if (errorMessageIdsLength > 0) {
      if (
        errorMessageIdsLength === 1 &&
        errorMessageIds[0] === 'avScan.virusFound'
      ) {
        const msgOptions: MsgBoxOptions = {
          title: t('node.download.errors.av_scan_virus_found.title'),
          btnActions: {
            confirm: {
              text: t('node.download.errors.av_scan_virus_found.btn.agree'),
            },
          },
          contentComponent: createNodesTable(
            logEntries,
            t(
              'node.download.errors.av_scan_virus_found.message',
              logEntries.length,
            ),
          ),
          msgBoxType: 'checkbox',
          msgBoxTypeFormFieldProps: {
            label: t(
              'node.download.errors.av_scan_virus_found.checkbox.label',
              logEntries.length,
            ),
          },
        }

        return showMsgBoxVirus(msgOptions, logEntries[0].level)
      }
      if (
        errorMessageIdsLength === 1 &&
        errorMessageIds[0] === constants.AV_SCAN_FILE_TO_BIG
      ) {
        const msgOptions: MsgBoxOptions = {
          title: t('node.download.errors.av_scan_file_too_big.title'),
          contentComponent: createNodesTable(
            logEntries,
            t(
              'node.download.errors.av_scan_file_too_big.message',
              logEntries.length,
            ),
          ),
          btnActions: {
            confirm: {
              text: t('node.download.errors.av_scan_file_too_big.btn.agree'),
            },
          },
          msgBoxType: 'checkbox',
          msgBoxTypeFormFieldProps: {
            label: t(
              'node.download.errors.av_scan_file_too_big.checkbox.label',
            ),
          },
        }
        console.log(msgOptions)

        return showMsgBoxVirus(msgOptions, logEntries[0].level)
      }
      if (
        errorMessageIdsLength === 2 &&
        errorMessageIds.includes('avScan.fileTooBig') &&
        errorMessageIds.includes('avScan.virusFound')
      ) {
        const msgOptions: MsgBoxOptions = {
          title: t(
            'node.download.errors.av_scan_virus_found_and_file_too_big.title',
          ),
          contentComponent: createNodesTable(
            logEntries,
            t(
              'node.download.errors.av_scan_virus_found_and_file_too_big.message',
              logEntries.length,
            ),
          ),
          btnActions: {
            confirm: {
              text: t(
                'node.download.errors.av_scan_virus_found_and_file_too_big.btn.agree',
              ),
            },
          },
          msgBoxType: 'checkbox',
          msgBoxTypeFormFieldProps: {
            label: t(
              'node.download.errors.av_scan_virus_found_and_file_too_big.checkbox.label',
              logEntries.length,
            ),
          },
        }

        return showMsgBoxVirus(msgOptions, logEntries[0].level)
      }
    }
  }

  return {
    showMsgBoxVirus,
    createNodesTable,
    showMsgBoxVirusOnDownload,
    showMsgBoxPreviewAvScanVirusFound,
    showMsgBoxPreviewAvScanFileToBig,
  }
}
