import * as Sentry from '@sentry/vue'
import first from 'lodash/first'
import last from 'lodash/last'
import { useDeepBoxCoreStore } from '@/stores/deepbox/core.ts'
import { useDeepAdminUsersMeStore } from '@/stores/deepadmin/users/users-me.ts'
import { useDeepBoxNodesInfoStore } from '@/stores/deepbox/nodes/info.ts'
import { useOrganization } from '@/composables/use-organization.ts'
import DeepErrorHandlerService from '@/services/DeepErrorHandlerService.ts'
import { DeepBoxError } from '@/models'
import { useKeycloak } from '@/composables/auth/use-keycloak.ts'
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'

/*
 *                                   !!! PLEASE READ !!!
 *
 * This `middleware` is very important! It's used to catch routes which are not defined in `vue-router`.
 * Also supports the "redirect" of old URLs ( which we used in the past ) and because of dependency
 * of 3rd system's ( like AbacusERP or DeepCloud Onboarding ) we need to still redirect the OLR urls to the new ones.
 * Pls be carefully when change/refactor something here
 *
 * */

export default async function ({
  next,
  to,
}: {
  next: NavigationGuardNext
  to: RouteLocationNormalized
}) {
  console.warn('ROUTE NOT FOUND => ', to)
  const deepAdminUsersMeStore = useDeepAdminUsersMeStore()
  const deepBoxCoreStore = useDeepBoxCoreStore()
  const deepBoxNodesInfoStore = useDeepBoxNodesInfoStore()

  const { selectOrg, setLastOrgIdIfValid } = useOrganization()

  const { isAuthenticated, login } = useKeycloak()

  const { path, fullPath, query } = to
  Sentry.captureMessage('Not found route navigation: ', { extra: { path } })
  const pathArray = to.path.slice(1).split('/')
  const firstItem = first(pathArray)
  const lastItem = last(pathArray)

  // redirect to new Onboarding
  if (/auth/.test(fullPath)) {
    // the new onboarding uses the same URLs as the DeepBox URLs

    // the default case is the removal of the '/auth'
    let url = fullPath.split('/auth')[1]
    url = url.slice(1) // remove the `/`

    // special case of the root page from new onboarding
    if (path === '/auth/register') {
      if (!Object.keys(query).includes('onboarding')) {
        // redirect to {onboarding}/register
        url = fullPath.split('/auth/register')[1]
      }
    }

    const onboardingRedirectUrl = `${
      import.meta.env.VITE_DEEP_ONBOARDING_BASE_URL
    }${url}`
    // Examples:
    // http://localhost:3000/auth/register?lang=de
    // http://localhost:3000/auth/register
    // http://localhost:3000/auth/register?onboarding=deepbox
    // http://localhost:3000/auth/register?onboarding=deepsign
    // http://localhost:3000/auth/register?onboarding=deepv
    // http://localhost:3000/auth/register/verify?email=a@b.ch&verify_type=email&onboarding=deepbox
    // http://localhost:3000/auth/register/new-user?key=qxqTZduw3Tl0AihN5Ix9u_ro75wgW4U9C91fOTurRWk
    // http://localhost:3000/auth/account
    // http://localhost:3000/auth/account/private
    // http://localhost:3000/auth/account/organization
    // http://localhost:3000/auth/account/verify

    // Log to sentry in order to get some feedback about users navigating to old URLs
    Sentry.captureMessage('DeepBox Onboarding route used: ', {
      extra: {
        onboardingNavigatedUrl: fullPath,
        onboardingRedirectUrl,
      },
    })

    // In order to redirect to the new onboarding just replace the domain and do the removal of '/auth'
    window.open(onboardingRedirectUrl, '_self')
    return
  }

  const isPreview = path.includes('preview')
  const isBoxDetails = isFolderIdSupported(path)

  let nodeId = ''
  let boxId = ''
  let typeId = ''
  let section = ''

  if (isAuthenticated.value) {
    await deepAdminUsersMeStore.fetchUserOrganizations()
    const userOrganizations = deepAdminUsersMeStore.organizations
    // Check if is preview or box details pages
    if (isPreview) {
      if (lastItem) {
        nodeId = lastItem
      }

      // Fetch node info in order to have the deepbox id and be able to fetch the company id
      try {
        await deepBoxNodesInfoStore.fetchNodeInfo(nodeId)
        if (deepBoxNodesInfoStore.nodeInfo?.deepBoxNodeId) {
          await deepBoxCoreStore.fetchType(
            deepBoxNodesInfoStore.nodeInfo?.deepBoxNodeId,
          )
        }
        // select organization
        if (deepBoxCoreStore.type?.companyId) {
          selectOrg(deepBoxCoreStore.type?.companyId, userOrganizations)
        }

        next({
          name: 'node-preview',
          params: { node: nodeId },
        })
        return
      } catch {
        DeepErrorHandlerService.error(
          new DeepBoxError('error.403.file_not_found_or_no_access', {
            statusCode: 403,
          }),
        )
      }
    } else if (isBoxDetails) {
      if (!lastItem || !firstItem) return

      // check if OrgID is in the URL
      // Example:
      // Is: http://localhost:5176/fced3206-ce61-415e-88b2-bdc97b1f59bf/types/96600fb4-8721-4e9f-9ff1-737fda2743bb/boxes/22c405e2-31ad-4b05-b66b-1d265e18435a/inbox
      // Should: http://localhost:5176/types/96600fb4-8721-4e9f-9ff1-737fda2743bb/boxes/22c405e2-31ad-4b05-b66b-1d265e18435a/inbox
      // or
      // Is: http://localhost:5176/d03e0a22-a044-4048-b200-04e26d2cdbc7/types/426a475a-d163-49e2-8d70-28ccdb288d35/boxes/8d2aef8a-20ab-4970-9331-7e7fd07d87f7/files/a7139ebd-1848-4a23-b705-da2e0f8734af
      // Should: http://localhost:5176/types/426a475a-d163-49e2-8d70-28ccdb288d35/boxes/8d2aef8a-20ab-4970-9331-7e7fd07d87f7/files/a7139ebd-1848-4a23-b705-da2e0f8734af
      // Search example:
      // Is: http://localhost:5176/d03e0a22-a044-4048-b200-04e26d2cdbc7/types/426a475a-d163-49e2-8d70-28ccdb288d35/boxes/8d2aef8a-20ab-4970-9331-7e7fd07d87f7/search?nodeId=8d2aef8a-20ab-4970-9331-7e7fd07d87f7&query=observer&offset=0&limit=200&tags=
      // Should: http://localhost:5176/types/426a475a-d163-49e2-8d70-28ccdb288d35/boxes/8d2aef8a-20ab-4970-9331-7e7fd07d87f7/search?nodeId=8d2aef8a-20ab-4970-9331-7e7fd07d87f7&query=observer&offset=0&limit=200&tags=
      const regexBoxView =
        /.+\/types\/.+\/boxes\/.+\/(inbox|queue|files|trash|search)/
      if (regexBoxView.test(path)) {
        // How it looks the pathArray for this case ? (Box View URLs )
        // 0 => :ORG-ID
        // 1 => "types"
        // 2 => :TYPE-ID
        // 3 => "boxes"
        // 4 => :BOX-ID
        // 5 => "files"
        // only by regexBoxViewWithNodeId
        // 6 => :NODE-ID

        // get the first param from pathArray, which is the Org ID ( from old URL's )
        // set this orgId as current org ID, before redirect
        const orgId = pathArray[0]
        if (orgId) {
          setLastOrgIdIfValid(orgId)
        }
        console.warn('ROUTE CONTAINS ORG WHICH IS NO LONGER SUPPORTED')

        typeId = pathArray[2]
        boxId = pathArray[4]
        section = pathArray[5]

        if (pathArray.length === 7) {
          nodeId = pathArray[6]
        }

        navigateToBoxViewIfExists(next, to, section, {
          type: typeId,
          box: boxId,
          node: nodeId,
        })
        return
      }

      if (isFolderIdSupported(lastItem)) {
        section = lastItem
        typeId = firstItem
        boxId = pathArray[pathArray.length - 2]
      } else {
        section = pathArray[pathArray.length - 2]
        nodeId = lastItem
        typeId = firstItem
        boxId = pathArray[pathArray.length - 3]
      }

      // Find the correct route to redirect
      navigateToBoxViewIfExists(next, to, section, {
        type: typeId,
        box: boxId,
        node: nodeId,
      })
      return
    }
  } else {
    if (isPreview || isBoxDetails) {
      const hostOrigin = window.location.origin
      const redirectUri = `${hostOrigin}${fullPath}`
      void login({ redirectUri })
      return
    } else {
      DeepErrorHandlerService.error(
        new DeepBoxError(undefined, {
          statusCode: 404,
          title: 'error.page_not_found',
        }),
      )
      next()
      return
    }
  }

  next()
}

function isFolderIdSupported(folderId: string) {
  return /inbox|queue|files|trash|search/.test(folderId)
}

type RouteParams = {
  type: string
  box: string
  node?: string
}

function navigateToBoxViewIfExists(
  next: NavigationGuardNext,
  to: RouteLocationNormalized,
  section: string,
  routeParams: RouteParams,
) {
  switch (section) {
    case 'search':
      next({
        name: 'organization-types-type-boxes-box-search',
        params: {
          type: routeParams.type,
          box: routeParams.box,
        },
        query: {
          ...to.query,
        },
      })
      break
    case 'queue':
    case 'inbox':
      next({
        name: 'organization-types-type-boxes-box-inbox',
        params: {
          type: routeParams.type,
          box: routeParams.box,
        },
      })
      break
    case 'files':
      if (routeParams.node) {
        next({
          name: 'organization-types-type-boxes-box-files-node',
          params: {
            type: routeParams.type,
            box: routeParams.box,
            node: routeParams.node,
          },
        })
        break
      }
      next({
        name: 'organization-types-type-boxes-box-files',
        params: {
          type: routeParams.type,
          box: routeParams.box,
        },
      })
      break
    case 'trash':
      if (routeParams.node) {
        next({
          name: 'organization-types-type-boxes-box-trash-node',
          params: {
            type: routeParams.type,
            box: routeParams.box,
            node: routeParams.node,
          },
        })
        break
      }
      next({
        name: 'organization-types-type-boxes-box-trash',
        params: {
          type: routeParams.type,
          box: routeParams.box,
        },
      })
      break
    // no default
  }
}
