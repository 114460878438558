<template>
  <v-text-field
    v-model="searchField"
    autofocus
    clearable
    density="compact"
    hide-details
    name="field-search"
    :placeholder="t('placeholders.search_in_box', { box: searchPlaceholder })"
    width="100%"
  />
</template>

<script lang="ts" setup>
import { computed, inject, ref, watch } from 'vue'
import { useBoxBreadcrumbs } from '@/composables/use-box-breadcrumbs.ts'
import { useNodeSection } from '@/composables/use-node-section.ts'
import last from 'lodash/last'
import { constants } from '@/constants'
import debounce from 'lodash/debounce'
import { useDeepBoxSearchStore } from '@/stores/deepbox/search.ts'
import { useDeepBoxCoreStore } from '@/stores/deepbox/core.ts'
import { useI18n } from 'vue-i18n'
import { useDeepBoxDeepBoxesBoxesNodesStore } from '@/stores/deepbox/deepboxes/boxes/nodes.ts'
import { useGlobalStore } from '@/stores/global/global.ts'
import { DeviceKey } from '@/plugins/device-detector-js.ts'

const deepBoxSearchStore = useDeepBoxSearchStore()
const deepBoxCoreStore = useDeepBoxCoreStore()
const deepBoxDeepBoxesBoxesNodesStore = useDeepBoxDeepBoxesBoxesNodesStore()
const globalStore = useGlobalStore()

const device = inject(DeviceKey)
const { t } = useI18n()

const searchField = ref('')

const { boxBreadcrumbsFiltered } = useBoxBreadcrumbs()

const { getNodeSectionTranslation } = useNodeSection()
const searchPlaceholder = computed(() => {
  const breadcrumb = last(boxBreadcrumbsFiltered.value)
  if (breadcrumb && breadcrumb.displayName) {
    return getNodeSectionTranslation(breadcrumb.displayName)
  }
  return ''
})

const searchNodeId = computed(() => {
  const searchNode = last(boxBreadcrumbsFiltered.value)
  if (!searchNode) return ''
  if (searchNode.nodeId === constants.SECTION_INBOX) {
    return deepBoxCoreStore.selectedBox?.roots?.queue
  }
  if (searchNode.nodeId === constants.SECTION_FILES) {
    return deepBoxCoreStore.selectedBox?.roots?.files
  }
  if (searchNode.nodeId === constants.SECTION_TRASH) {
    return deepBoxDeepBoxesBoxesNodesStore.getCurrentRootNodeId
  }
  return searchNode.nodeId
})

const mobileSearchPayload = computed(() => {
  return {
    nodeId: searchNodeId.value,
    query: searchField.value,
    offset: 0,
    limit: 50,
    tags: '',
  }
})

watch(
  () => searchField.value,
  (newValue) => {
    if (globalStore.mobileSearch && device?.isMobile) {
      deepBoxCoreStore.mobileSearchField = newValue
      searchForMobile()
    }
  },
)

watch(
  () => globalStore.mobileSearch,
  (newValue) => {
    if (!newValue) {
      searchField.value = ''
      // reset search store when out of mobile search
      deepBoxSearchStore.$reset()
    }
  },
)

const searchForMobile = debounce(async function () {
  await deepBoxSearchStore.fetchSearchResults(mobileSearchPayload.value)
}, 500)
</script>
